import { Card, Col, Row, Spinner, Stack } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import playIcon from '../../assets/landing/playIcon.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons'
import { faDotCircle, faCircle } from '@fortawesome/free-regular-svg-icons'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import { approveVideo, getVideos } from '../../api/videos'
import * as constants from '../../constants'
import { AppContext } from '../../context'

const AdminReviewVideos = () => {
  const { token, user, openVideoModal } = useContext(AppContext)
  const [recommended, setRecommended] = useState(false)
  const [staffPick, setStaffPick] = useState(false)
  const [selected, setSelected] = useState(0)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [isRejected, setIsRejected] = useState(false)
  const [rejectSelected, setRejectSelected] = useState('')

  const { data, isFetching, hasNextPage, fetchNextPage, refetch } =
    useInfiniteQuery(
      ['review_videos', token],
      ({ pageParam = 1 }) =>
        getVideos({ page: pageParam, status: 'Pending', all: 'true' }, token),
      {
        getNextPageParam: (lastPage) =>
          Number(lastPage.actual_page) < lastPage.total_pages
            ? Number(lastPage.actual_page) + 1
            : false,
        initialData: { pages: [{ data: [] }], pageParams: 1 },
        enabled: token !== '' && (user?.user_serializer.is_superuser ?? false),
      }
    )
  const videos = useMemo(
    () =>
      data.pages.reduce((prevVideos, page) => prevVideos.concat(page.data), []),
    [data]
  )

  const rejectOptions = [
    'Your video is more than 2 minutes long.',
    'Your video did not contain the competition video frames.',
    'Your video has not been uploaded to a supported social media platform (Instagram, TikTok, YouTube).',
    'Video did not comply with the terms and conditions of the platform.',
    'Other.',
  ]

  useEffect(() => {
    setRecommended(false)
    setStaffPick(false)
  }, [selected])

  const handleMarkVideo = async (state) => {
    if (buttonDisabled) return
    setButtonDisabled(true)

    const body = {
      is_staff_pick: `${staffPick}`,
      recommended: `${recommended}`,
    }
    if (state === 'accept') body.status = 'Approved'
    else {
      body.status = 'Rejected'
      body.reason = rejectSelected
    }

    const response = await approveVideo(token, videos[selected].id, body)

    if (response.error) console.error(response.error)
    else if (response) {
      // setVideos([])
      // setPage(1)
      refetch()
    }

    setSelected(0)
    setIsRejected(false)
    setRejectSelected('')
    setButtonDisabled(false)
  }

  const handleSelectVideo = (index) => {
    setSelected(index)
    scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <>
      {videos.length !== 0 ? (
        <>
          <Row className="AdminReviewVideos__Video_Selected mb-4 ">
            <Col className="h-50vh">
              <Card className="video-card crop h-100">
                <Card.Img
                  src={`${videos[selected]?.thumbnail.indexOf('http://') === 0 || videos[selected]?.thumbnail.indexOf('https://') === 0 ? '' : constants.URL}${videos[selected]?.thumbnail}`}
                  className="h-100"
                  alt="Card image"
                />
                {isRejected ? (
                  <Card.ImgOverlay className="h-100 d-flex flex-column p-4 bg-gray">
                    <p className="fw-bold">
                      Select a reason for rejecting this video
                    </p>
                    <div className="d-flex flex-column gap-3 mt-2">
                      {rejectOptions.map((option, index) => (
                        <div
                          key={index}
                          className="d-flex cursor-pointer"
                          onClick={() => setRejectSelected(option)}
                        >
                          {rejectSelected === option ? (
                            <FontAwesomeIcon
                              icon={faDotCircle}
                              size="lg"
                              className="me-2"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faCircle}
                              size="lg"
                              className="me-2"
                            />
                          )}{' '}
                          {option}
                        </div>
                      ))}
                    </div>
                    <Button
                      variant="rs"
                      className="ms-auto mt-auto"
                      onClick={() => handleMarkVideo('reject')}
                      disabled={rejectSelected === ''}
                    >
                      Done
                    </Button>
                  </Card.ImgOverlay>
                ) : (
                  <Card.ImgOverlay className="text-white h-100 d-flex flex-column justify-content-end p-0">
                    <img
                      src={playIcon}
                      className="play-icon cursor-pointer"
                      alt=""
                      onClick={() => openVideoModal({...videos[selected], isAdmin: true })}
                    />
                    <div
                      className="admin-staff-pick cursor-pointer"
                      onClick={() => setRecommended((prev) => !prev)}
                    >
                      <div className="admin-staff-pic-span">
                        {recommended ? (
                          <FontAwesomeIcon
                            icon={faDotCircle}
                            size="lg"
                            className="me-2"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faCircle}
                            size="lg"
                            className="me-2"
                          />
                        )}{' '}
                        Recommended
                      </div>
                    </div>
                    <div
                      className="admin-staff-pick cursor-pointer"
                      onClick={() => setStaffPick((prev) => !prev)}
                    >
                      <div className="admin-staff-pic-span">
                        {staffPick ? (
                          <FontAwesomeIcon
                            icon={faDotCircle}
                            size="lg"
                            className="me-2"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faCircle}
                            size="lg"
                            className="me-2"
                          />
                        )}{' '}
                        Staff pick
                      </div>
                    </div>
                    <div className='flex-grow-1' />
                    <div>
                      <Row
                        className="text-center gap-1"
                        style={{
                          margin: '0',
                          borderBottomLeftRadius: '5px',
                          borderBottomRightRadius: '5px',
                          overflow: 'hidden',
                        }}
                      >
                        <button
                          className="accept-reject col"
                          onClick={() => setIsRejected(true)}
                        >
                          <p className="text-white mb-0 p-0">
                            <FontAwesomeIcon icon={faX} className="me-2" />{' '}
                            Reject video
                          </p>
                        </button>
                        <button
                          className="accept-reject col p-0"
                          onClick={() => handleMarkVideo('accept')}
                        >
                          <p className="text-white mb-0">
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              size="lg"
                              className="me-2"
                            />{' '}
                            Accept video
                          </p>
                        </button>
                      </Row>
                    </div>
                  </Card.ImgOverlay>
                )}
              </Card>
            </Col>
            <Col className="h-50vh">
              <div className="admin-video-description">
                <div className="admin-video-description-row overflow-y-auto">
                  <h2 className="mb-4">{videos[selected].name}</h2>
                  <p>{videos[selected].description}</p>
                </div>
                <div className="admin-video-description-row overflow-y-auto bg-light p-3">
                  <h5 className="mb-3">
                    {videos[selected].user_name}{' '}
                    {videos[selected].user_last_name}
                  </h5>
                  <p className="admin-label">Email</p>
                  <p className="admin-field">{videos[selected].user_email}</p>
                  <Row>
                    <Col>
                      <p className="admin-label">Age</p>
                      <p className="admin-field">{videos[selected].user_age}</p>
                    </Col>
                    <Col>
                      <p className="admin-label">Mobile</p>
                      <p className="admin-field">
                        {videos[selected].user_phone}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="admin-label">Country</p>
                      <p className="admin-field">
                        {videos[selected].user_country}
                      </p>
                    </Col>
                    <Col>
                      <p className="admin-label">City</p>
                      <p className="admin-field">
                        {videos[selected].user_city}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-4">
            {videos.map((video, index) => (
              <Col key={video.id} xl={3} md={4} sm={12} className="mb-4">
                <Card
                  className={
                    'video-card crop cursor-pointer ' +
                    (selected === index && 'card-bordered')
                  }
                  onClick={() => handleSelectVideo(index)}
                >
                  <Card.Img
                    src={`${video.thumbnail.indexOf('http://') === 0 || video.thumbnail.indexOf('https://') === 0 ? '' : constants.URL}${video.thumbnail}`}
                    alt="Card image"
                  />
                  <Card.ImgOverlay className="text-white h-100 d-flex flex-column justify-content-end">
                    <div>
                      <Stack
                        direction="horizontal"
                        gap={4}
                        className="justify-content-center"
                      >
                        <div></div>
                      </Stack>
                    </div>
                  </Card.ImgOverlay>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      ) : (
        <></>
      )}
      {token === '' || isFetching ? (
        <div className="text-center" style={{ marginBottom: '49.25px' }}>
          <Spinner animation="border" variant="warning" />
        </div>
      ) : hasNextPage ? (
        <div className="text-center mb-5" onClick={() => fetchNextPage()}>
          <Button variant="rs-third">Load more videos</Button>
        </div>
      ) : videos.length === 0 ? (
        <div>No videos to review</div>
      ) : (
        <div style={{ height: '86px' }} />
      )}
    </>
  )
}

export default AdminReviewVideos
