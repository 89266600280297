import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext } from 'react'
import { AppContext } from '../context'

const VotesRemaining = () => {
  const { remainingVotes } = useContext(AppContext)

  return (
    <div className='Vote'>
      <p className="Vote_Text font_roboto_slab mb-0">
          Votes remaining today
      </p>
      <div className="Vote_Heart">
        <FontAwesomeIcon icon={faHeart} />
        <p className="Vote_Heart_Number font_roboto_slab mb-0">
          {remainingVotes}
        </p>
      </div>
    </div>
  )
}

export default VotesRemaining