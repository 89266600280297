import {
  Col,
  Container,
  Row,
  Spinner,
  Modal,
  Button,
  Table,
} from 'react-bootstrap'
import { getLeaderboard } from '../api/videos'
import { useQuery } from 'react-query'
import LeaderboardPosition from './LeaderboardPosition'
import SocialIcon from './SocialIcon'
import { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { AppContext } from '../context'

const Leaderboard = ({ refetch }) => {
  const {
    token,
    timelineCompetitions,
  } = useContext(AppContext)
  const [show, setShow] = useState(false)

  const competition_id =
    timelineCompetitions?.currentCompetition?.id ||
    (timelineCompetitions?.lastCompetitions?.length
      && timelineCompetitions.lastCompetitions[0].id)

  const { isFetching, data, refetch: refetchLeaderboard } = useQuery(
    ['getLeaderboard', competition_id, token],
    (() => getLeaderboard({ competition_id }, token)),
    {
      initialData: [],
    }
  )

  const handleClose = () => setShow(false)
  const handleShow = () => {
    setShow(true)
    refetchLeaderboard()
  }
  return (
    <section className="Leaderboard py-5">
      <Container>
        <Row>
          <Col
            sm={12}
            md={12}
            className="d-flex flex-column justify-content-center"
          >
            {timelineCompetitions.currentCompetition && (
              <>
                <h2 className="LeaderboardTitle text-center mb-0">
                  {timelineCompetitions.currentCompetition.leaderboard_winners_title2}
                </h2>
              </>
            )}
            {!timelineCompetitions.currentCompetition && timelineCompetitions.lastCompetitions.length !== 0 && (
              <>
                <h2 className="LeaderboardTitle text-center mb-2">
                  {timelineCompetitions.lastCompetitions[0].leaderboard_winners_title2}
                </h2>
              </>
            )}
            <div className="d-flex flex-column">
              {data &&
                data
                  .slice(0, 5)
                  .map((element, index) => (
                    <LeaderboardPosition
                      video={element}
                      key={element.id}
                      position={index + 1}
                      id={element.id}
                      socialNet={element.hosted_in}
                      likes={element.votes}
                      name={element.name}
                      videoName={element.name}
                      videoSrc={element.video_link}
                      firstName={element.user_name}
                      lastName={element.user_last_name}
                      description={element.description}
                      staffPick={element.is_staff_pick}
                      image={element.thumbnail}
                      voted={element.already_voted}
                      country={element.user_country}
                      refetch={refetch}
                    />
                  ))}
            </div>

            <div className="d-flex justify-content-center text-center mt-2">
              {isFetching ? (
                <div className='px-5 mt-4'>
                  <Spinner animation="border" variant="warning" />
                </div>
              ) : (
                <Button
                  variant={'rs-third'}
                  className="px-5 mt-4 d-block d-md-inline-block"
                  onClick={handleShow}
                >
                  Explore Leaderboard
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={show} className="p-0" dialogClassName="modal-90w" onHide={handleClose}>
        <Modal.Header closeButton>
          {timelineCompetitions.currentCompetition && (
            <h4>
              {timelineCompetitions.currentCompetition.leaderboard_winners_title} - Top 20
            </h4>
          )}
          {!timelineCompetitions.currentCompetition && timelineCompetitions.lastCompetitions.length !== 0 && (
            <h4>
              {timelineCompetitions.lastCompetitions[0].leaderboard_winners_title} - Top 20
            </h4>
          )}
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Video</th>
                <th>Platform</th>
              </tr>
            </thead>
            <tbody>
              {isFetching ? (
                <>
                  <tr className="text-center">
                    <Spinner animation="border" variant="warning" />
                  </tr>
                </>
              ) : (
                data &&
                data.map((element, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{element.user_name + ' ' + element.user_last_name}</td>
                    <td>{element.name}</td>
                    <td>
                      <SocialIcon socialMedia={element.hosted_in} />
                      <span className="d-none d-md-block">
                        {element.hosted_in}
                      </span>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </section>
  )
}

Leaderboard.propTypes = {
  refetch: PropTypes.func,
}

export default Leaderboard
