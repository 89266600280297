import { Nav, Spinner } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import VideoCard from '../components/VideoCard'
import PrevIcon from '../assets/landing/prevIcon.png'
import NextIcon from '../assets/landing/nextIcon.png'
import CompetitionLogo from '../assets/landing/competitionLogo.png'
import { Link, useSearchParams } from 'react-router-dom'
import { useState, createContext, useContext, useEffect, useMemo } from 'react'
import { Navigation, A11y } from 'swiper'
import { getVideos, getVideosDetailsNew, get_selectedWinner } from '../api/videos'
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import { useQuery } from 'react-query'
import Leaderboard from '../components/Leaderboard'
import SubmitVideo from '../components/SubmitVideo'
import Prizes from '../components/Prizes'
import HowToEnter from '../components/HowToEnter'
import DownloadModal from '../components/DownloadModal'
import CountdownTimer from '../components/Countdown'
import { AppContext } from '../context'
import { Helmet } from 'react-helmet'
import YouTubeVideoModal from '../components/YouTubeVideoModal'
import heroBackgroundFrame from '../assets/videoFrames/hero_background (2)_frame.jpg'
import { URL as API_URL } from '../constants'
import YoutubeEmbed from '../components/YoutubeEmbed'
import classNames from 'classnames'

export const DownloadModalContext = createContext()

const Home = () => {
  const {
    token,
    unregisteredUserId,
    openVideoModal,
    competitionInfo,
    timelineCompetitions,
  } = useContext(AppContext)
  const [filter, setFilter] = useState('recommended')
  const [show, setShow] = useState(false)
  const [searchParams] = useSearchParams()
  const { isFetching, data, refetch } = useQuery(
    ['videos', filter, unregisteredUserId],
    () =>
      getVideos(
        {
          page: 1,
          per_page: 12,
          all: 'true',
          hosted_in:
            filter === 'TikTok' ||
              filter === 'Instagram' ||
              filter === 'Youtube'
              ? filter
              : '',
          order_by:
            filter === '-votes' || filter === '-upload_time' ? filter : '',
          recommended: filter === 'recommended' ? filter : '',
          unregistered_user_id: !token ? unregisteredUserId : '',
        },
      ),
    {
      initialData: { total_pages: 1, actual_page: 1, data: [] },
    }
  )
  const isCompetitionCloseToEnd = useMemo(() => {
    const end_date = competitionInfo?.competition.end_date

    if (!end_date) {
      return false
    }

    const competitionEnd = new Date(end_date).getTime()
    const today = new Date().getTime()
    const sevenDays = 7 * 24 * 60 * 60 * 1000 // día, horas, minutos, segundos, milisegundos
    return competitionEnd - today < sevenDays
  }, [competitionInfo])

  const competition_id =
    timelineCompetitions?.currentCompetition?.id ||
    (timelineCompetitions?.lastCompetitions?.length
      && timelineCompetitions.lastCompetitions[0].id)

  const { data: selectedWinnerData } = useQuery(
    ['getSelectedWinner', competition_id],
    () => {
      if (competition_id) {
        return get_selectedWinner({ competition_id })
      }
    },
    {
      initialData: {
        data: {
          admin_description: '',
          description: '',
          hosted_in: '',
          id: null,
          is_banned_from_leaderboard: false,
          is_staff_pick: false,
          last_edited_by: 0,
          name: '',
          reason_rejected: '',
          recommended: false,
          status: '',
          thumbnail: '',
          upload_time: '',
          user_age: '',
          user_city: '',
          user_country: '',
          user_email: '',
          user_last_name: '',
          user_name: '',
          user_phone: '',
          video_link: '',
        },
        ok: false,
      },
    }
  )
  const [showYoutubeModal, setShowYoutubeModal] = useState(false)

  const handleFilter = (value) => {
    setFilter(value)
  }

  const handleOpenVideo = async (video_id) => {
    const { ok, data } = await getVideosDetailsNew(token, video_id, {
      unregistered_user_id: unregisteredUserId,
    })

    if (!ok) console.error(data)
    else {
      openVideoModal({ ...data, refetch })
    }
  }

  const openShowYoutubeModal = () => {
    setShowYoutubeModal(true)
  }

  const closeShowYoutubeModal = () => {
    setShowYoutubeModal(false)
  }

  const handleOpenVideoExample = async () => {

    openVideoModal({
      hosted_in: competitionInfo.competition.video_example.hosted_in,
      video_link: competitionInfo.competition.video_example.video_link,
      name: competitionInfo.competition.video_example.title,
      description: competitionInfo.competition.video_example.description,
      user_name: competitionInfo.competition.video_example.participant_name,
      isExample: true,
    })
  }

  useEffect(() => {
    const video_id = searchParams.get('video_id')
    if (video_id) handleOpenVideo(video_id)
  }, [unregisteredUserId])

  const handleOpenVideoWinner = async () => {
    openVideoModal({
      hosted_in: selectedWinnerData.data.hosted_in,
      video_link: selectedWinnerData.data.video_link,
      name: selectedWinnerData.data.name,
      description: selectedWinnerData.data.description,
      user_name: selectedWinnerData.data.user_name,
      user_last_name: selectedWinnerData.data.user_last_name,
    })
  }

  return (
    <>
      <Helmet>
        <title>
          Outback Explorer Competition | Rescue Swag + Royal Flying Doctor
          Service QLD
        </title>
      </Helmet>
      <DownloadModalContext.Provider value={{ show, setShow }}>
        <section className="Hero mb-5">
          {competitionInfo && (
            <>
              {competitionInfo.competition.background_video_youtube && (
                <YoutubeEmbed
                  disableControls={true}
                  video={competitionInfo.competition.background_video_youtube}
                  className={classNames('Hero__Video', { withVertical: competitionInfo.competition.background_video_youtube_mobile || competitionInfo.competition.background_mobile })}
                />
              )}
              {competitionInfo.competition.background_video_youtube_mobile && (
                <YoutubeEmbed
                  disableControls={true}
                  video={competitionInfo.competition.background_video_youtube_mobile}
                  className={classNames('Hero__Video-Vertical', { withHorizontal: competitionInfo.competition.background_video_youtube || competitionInfo.competition.background })}
                />
              )}
              {competitionInfo.competition.background && !competitionInfo.competition.background_video_youtube && (
                <img className={classNames('Hero__Image', {withImgVertical: competitionInfo.competition.background_mobile || competitionInfo.competition.background_video_youtube_mobilecompetitionInfo.competition.background_video_youtube_mobile})} src={`${competitionInfo.competition.background.startsWith('http') ? '' : API_URL}${competitionInfo.competition.background}`} alt="bannerBackground" />
              )}
              {competitionInfo.competition.background_mobile && !competitionInfo.competition.background_video_youtube_mobile && (
                <img className={classNames('Hero__Image-Vertical', {withImgHorizontal: competitionInfo.competition.background || competitionInfo.competition.background_video_youtube})} src={`${competitionInfo.competition.background_mobile.startsWith('http') ? '' : API_URL}${competitionInfo.competition.background_mobile}`} alt="bannerBackground" />
              )}
            </>
          )}
          <div className='Hero__Background' />
          <Container
            className='d-flex flex-column Hero__Container justify-content-center align-items-center gap-4 h-100 px-4 text-center'
          >
            {competitionInfo && (
              <>
                <img src={CompetitionLogo} alt="Competition logo" width={127} />
                <h1 className="text-rs-yellow hero-text font_roboto_slab ">
                  {competitionInfo.state === 'finished' ? (
                    selectedWinnerData ? (
                      <>
                        Congratulations to the winner!
                        <p className="my-2 hero-text-1 text-white">{selectedWinnerData.data.user_name} {selectedWinnerData.data.user_last_name}</p>
                      </>
                    ) : (
                      'Competition now closed'
                    )
                  ) : (
                    competitionInfo.competition.title
                  )}
                </h1>
                {competitionInfo.state === 'finished' ? (
                  !selectedWinnerData.data.error ? (
                    <>
                      <p className="mb-0 hero-text-3">
                        Thank you to everyone who entered & voted!
                      </p>
                      <Link to={`/winners/${timelineCompetitions.lastCompetitions[0].id}`}>
                        <Button variant="rs" className="px-5 my-5" >
                          View all winners
                        </Button>
                      </Link>
                    </>
                  ) : (
                    <>
                      <p className="mb-0 hero-text-2">
                        Thank you to everyone who entered & voted!
                      </p>
                      <Link to="/all-videos/">
                        <Button variant="rs" className="px-5">
                          Watch videos
                        </Button>
                      </Link>
                    </>
                  )
                ) : null}
                {competitionInfo.state === 'nearFuture' && (
                  <>
                    <p className="mb-0 hero-text-2">
                      We want to see videos of your favourite destinations,
                      activities and wildlife from around Australia!
                    </p>

                    <div className='d-flex align-items-center gap-3'>
                      <div>
                        <p className="mb-0 hero-text-2">Voting opens in</p>
                        <CountdownTimer targetDate={competitionInfo.competition.start_date} />
                      </div>
                      {isCompetitionCloseToEnd && (
                        <>
                          <div className='d-flex'>
                            <hr className='vertLine' />
                            <div>
                              <p className="mb-0 hero-text-2">
                                Voting closes in
                              </p>
                              <CountdownTimer targetDate={competitionInfo.competition.end_date} />

                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="d-flex flex-column flex-md-row gap-3 gap-md-5 w-max-cont">
                      <Button
                        id="Banner-Enter-Competition-Btn"
                        variant="rs px-5"
                        href="#enter-competition"
                      >
                        Enter competition
                      </Button>
                      <Button
                        variant="rs-white"
                        className="px-5"
                        onClick={handleOpenVideoExample}
                      >
                        Watch example video
                      </Button>
                    </div>
                  </>
                )}
                {competitionInfo.state === 'ongoing' && (
                  <>
                    {isCompetitionCloseToEnd && (
                      <>
                        <p className="mb-0 hero-text-2">
                          Voting closes in
                        </p>
                        <CountdownTimer targetDate={competitionInfo.competition.end_date} />
                      </>
                    )}
                    <div className="d-flex flex-column flex-md-row gap-3 gap-md-5 w-max-cont">
                      <Button
                        variant="rs"
                        href="#enter-competition"
                        className="px-5"
                      >
                        Submit your video
                      </Button>
                      <Button
                        variant="rs-white"
                        className="px-5"
                        onClick={handleOpenVideoExample}
                      >
                        Watch example video
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}
          </Container>
        </section>
        {competitionInfo && competitionInfo.state !== 'nearFuture' && (
          <section>
            <Container>
              <Nav
                variant="tabs"
                defaultActiveKey="recommended"
                className="mb-4 AllVideos"
                onSelect={handleFilter}
              >
                <Nav.Item className="w-max-cont">
                  <Link to="/all-videos/latest">
                    <Button className="w-max-cont" variant={'rs-third-slider'}>
                      See all videos
                    </Button>
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="w-max-cont" eventKey="recommended">
                    Recommended
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="w-max-cont" eventKey="-upload_time">
                    Latest
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="w-max-cont" eventKey="-votes">
                    Most popular
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="w-max-cont" eventKey="TikTok">
                    TikTok
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="w-max-cont" eventKey="Instagram">
                    Instagram
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="w-max-cont" eventKey="Youtube">
                    YouTube
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              {!data.error && data.data.length !== 0 ? (
                <div className="slider-container">
                  <Swiper
                    modules={[Navigation, A11y]}
                    navigation={{
                      nextEl: '.review-swiper-button-next',
                      prevEl: '.review-swiper-button-prev',
                    }}
                    spaceBetween={20}
                    slidesPerView={1}
                    onSlideChange={() => { }}
                    onSwiper={() => { }}
                    breakpoints={{
                      576: {
                        slidesPerView: 2,
                      },
                      992: {
                        slidesPerView: 3,
                      },
                      1200: {
                        slidesPerView: 4,
                      },
                    }}
                  >
                    {data.data.map((element) => (
                      <SwiperSlide key={element.id}>
                        <VideoCard
                          id={element.id}
                          socialNet={element.hosted_in}
                          likes={element.votes}
                          name={element.name}
                          videoName={element.name}
                          videoSrc={element.video_link}
                          firstName={element.user_name}
                          lastName={element.user_last_name}
                          description={element.description}
                          staffPick={element.is_staff_pick}
                          new={element.new}
                          image={element.thumbnail}
                          voted={element.already_voted}
                          video={element}
                          refetch={refetch}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <img src={PrevIcon} className="review-swiper-button-prev" />
                  <img src={NextIcon} className="review-swiper-button-next" />
                </div>
              ) : isFetching ? (
                <div className="text-center" style={{ padding: '187.625px 0' }}>
                  <Spinner animation="border" variant="warning" />
                </div>
              ) : (
                <></>
              )}
            </Container>
          </section>
        )}
        {competitionInfo && competitionInfo.state !== 'finished' ? <HowToEnter openVideo={openShowYoutubeModal} /> : <></>}
        <Prizes />
        {competitionInfo && competitionInfo.state !== 'finished' ? <SubmitVideo /> : <></>}
        {competitionInfo && competitionInfo.state !== 'nearFuture' ? <Leaderboard refetch={refetch} /> : <></>}
        <DownloadModal />
        <YouTubeVideoModal
          isVisible={showYoutubeModal}
          handleClose={closeShowYoutubeModal}
          videoLinkId="2UKo7QLMx-U"
        />
      </DownloadModalContext.Provider >
    </>
  )
}

export default Home
