import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import logo from '../assets/landing/rfds-rs-logo.png'
import { Link, NavLink } from 'react-router-dom'
import { useContext } from 'react'
import { AppContext } from '../context'
import { Nav } from 'react-bootstrap'

const AuthHeader = () => {
  const { competitionInfo } = useContext(AppContext)
  return (
    <>
      <div className="TopNavbar">
        <a
          href="https://www.rescueswag.com.au/"
          target="_blank"
          rel="noreferrer"
        >
          <u>GET 15% OFF YOUR RESCUE SWAG</u>
        </a>{' '}
          - USE CODE OUTBACK15
      </div>
      <Navbar collapseOnSelect expand="lg" bg="white" variant="light">
        <Container>
          <Link to="/" className="navbar-brand">
            <img
              src={logo}
              width={240}
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="nav-rs py-3 py-lg-0">
              <NavLink to="/" className="nav-link">
                Home
              </NavLink>
              {competitionInfo && competitionInfo.state !== 'finished' && (
                <NavLink to="/#how-to-enter" className="nav-link">
                  How to enter
                </NavLink>
              )}
              {competitionInfo && competitionInfo.state === 'ongoing' && (
                <NavLink to="/all-videos/" className="nav-link">
                  Watch & Vote
                </NavLink>
              )}
              <NavLink
                to="/who-we-are"
                className="nav-link"
              >
                  Who We Are
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default AuthHeader
