import { Col, Form, Nav, Row, Spinner } from 'react-bootstrap'
import VideoCard from '../components/VideoCard'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { useContext, useMemo, useState } from 'react'
import { getVideos } from '../api/videos'
import { useInfiniteQuery, useQuery } from 'react-query'
import { AppContext } from '../context'
import { Helmet } from 'react-helmet'
import { useSearchParams } from 'react-router-dom'
import CompetitionsDropdown from '../components/CompetitionsDropdown'
import { getCompetitionsDetail } from '../api/competitions'

const filtersTypes = { recommended: 'recommended', latest: '-upload_time', 'most-popular': '-votes', tiktok: 'TikTok', instagram: 'Instagram', youtube: 'Youtube' }

const AllVideos = () => {
  const { token, unregisteredUserId, user } = useContext(AppContext)
  let [searchParams, setSearchParams] = useSearchParams()

  const [filter, setFilter] = useState(filtersTypes[searchParams.get('type')] ?? 'recommended')
  const [search, setSearch] = useState('')

  const competition_id = Number(searchParams.get('competition_id'))

  const { data, isFetching, hasNextPage, fetchNextPage, refetch } =
    useInfiniteQuery(
      ['all-videos', filter, token, competition_id, searchParams.get('type'), unregisteredUserId, search],
      ({ pageParam = 1 }) =>
        getVideos({
          page: pageParam,
          per_page: 20,
          hosted_in: filter === 'TikTok' || filter === 'Instagram' || filter === 'Youtube' ? filter : '',
          order_by: filter === '-votes' || filter === '-upload_time' ? filter : '',
          recommended: filter === 'recommended' ? filter : '',
          unregistered_user_id: !token ? unregisteredUserId : '',
          all: !competition_id ? 'true' : '',
          competition_id: competition_id,
          search,
        }, token),
      {
        getNextPageParam: (lastPage) =>
          lastPage.actual_page < lastPage.total_pages
            ? Number(lastPage.actual_page) + 1
            : false,
        initialData: { pages: [{ data: [] }], pageParams: 1 },
      }
    )

  const { data: competitionsDetail } = useQuery(
    ['getCompetitions', competition_id],
    () => {
      if (competition_id && competition_id !== 0) {
        return getCompetitionsDetail(competition_id)
      }
    },
    {
      initialData: {
        data: {
          name: '',
        },
        ok: false,
      },
    }
  )

  const videos = useMemo(
    () =>
      data.pages.reduce((prevVideos, page) => prevVideos.concat(page.data), []),
    [data]
  )

  const handleFilter = (value) => {
    setFilter(value)
    setSearchParams((prev) => {
      const newParams = { type: value }
      if (prev.get('competition_id'))
        newParams.competition_id = prev.get('competition_id')
      return newParams
    })
  }

  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  return (
    <>
      <Helmet>
        <title>Outback Explorer Competition | Rescue Swag + Royal Flying Doctor Service QLD</title>
      </Helmet>
      <Container className="WatchNow-container">
        <Form.Control
          type="text"
          placeholder='Search'
          value={search}
          onChange={handleSearch}
        />
        {competitionsDetail.ok && (
          <>
            <div className='d-flex flex-column gap-3 mt-4'>
              <h4>Competition Year: {competitionsDetail.data.name}</h4>
              <p>{competitionsDetail.data.explanation}</p>
            </div>
          </>
        )}
        <CompetitionsDropdown className='mobile' />
        <div className='d-flex justify-content-between align-items-center'>
          <Nav
            variant="tabs"
            defaultActiveKey={filtersTypes[searchParams.get('type')] ?? 'recommended'}
            className="my-4 AllVideos"
            onSelect={handleFilter}
          >
            <Nav.Item>
              <Nav.Link className='w-max-cont' eventKey="recommended">Recommended</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className='w-max-cont' eventKey="-upload_time">Latest</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className='w-max-cont' eventKey="-votes">Most popular</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className='w-max-cont' eventKey="TikTok">TikTok</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className='w-max-cont' eventKey="Instagram">Instagram</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className='w-max-cont' eventKey="Youtube">YouTube</Nav.Link>
            </Nav.Item>
          </Nav>
          <CompetitionsDropdown />
        </div>
        <Row className="mb-4">
          {videos.map((element) => (
            <Col xl={3} md={4} sm={12} key={element.id}>
              <VideoCard
                key={element.id}
                id={element.id}
                socialNet={element.hosted_in}
                likes={element.votes}
                name={element.name}
                videoName={element.name}
                videoSrc={element.video_link}
                firstName={element.user_name}
                lastName={element.user_last_name}
                description={element.description}
                staffPick={element.is_staff_pick}
                new={element.new}
                image={element.thumbnail}
                voted={element.already_voted}
                votes={element.votes}
                video={element}
                refetch={() => refetch()}
              />
            </Col>
          ))}
        </Row>
        {isFetching ? (
          <div className="text-center" style={{ marginBottom: '49.25px' }}>
            <Spinner animation="border" variant="warning" />
          </div>
        ) : hasNextPage ? (
          <div className="text-center mb-5" onClick={() => fetchNextPage()}>
            <Button variant="rs-third">Load more videos</Button>
          </div>
        ) : videos.length !== 0 ? (
          <div style={{ height: '86px' }} />
        ) : search.length !== 0 ? (
          <div>no results to show</div>
        ) : (
          <div>No videos to view</div>
        )}
      </Container>
    </>
  )
}

export default AllVideos
