import PropTypes from 'prop-types'

const YoutubeEmbed = ({ video, className, disableControls = false }) => {

  const getEmbedId = (video) => {

    const ultimoSlashIndex = video.lastIndexOf('/')
    if (ultimoSlashIndex !== -1) {
      return video.slice(ultimoSlashIndex + 1)
    } else {
      return null
    }
  }

  const videoID = getEmbedId(video)

  return (
    <div className="d-flex justify-content-center">
      <iframe
        className={className}
        src={`${video}${
          disableControls
            ? `?controls=0&autoplay=1&mute=1&playsinline=1&playlist=${videoID}&loop=1`
            : ''
        }`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  )
}

YoutubeEmbed.propTypes = {
  video: PropTypes.string.isRequired,
  className: PropTypes.string,
  disableControls: PropTypes.bool,
}

export default YoutubeEmbed
