import {Card, Col, Stack} from 'react-bootstrap'
import * as constants from '../constants'
import playIcon from '../assets/landing/playIcon.png'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { AppContext } from '../context'

const AdminRejectedVideoCard = (props) => {
  const { openVideoModal } = useContext(AppContext)

  return (
    <Col key={props.id} xl={3} md={4} sm={12} className="mb-4">
      <Card className='video-card crop h-100'>
        <div style={{position:'absolute',zIndex:100, bottom:0, backgroundColor:'rgba(52, 52, 52, 0.6)', width:'100%', padding:5}}>
          <div>
            <p style={{color:'white', fontSize:15, marginTop:5, marginLeft:5}}><b>{props.videoName}</b></p>
            <hr style={{color:'white'}} />
            <p onClick={()=>{props.pendingVideo(props.id)}} style={{textAlign:'center', color:'lightgrey', fontSize:16, marginTop:15, cursor:'pointer'}}>Mark for <span style={{fontWeight:'bold'}}>Review</span>
            </p>
          </div>
        </div>
        <Card.Img src={`${props.image.indexOf('http://') === 0 || props.image.indexOf('https://') === 0 ? '' : constants.URL}${props.image}`} alt="Card image" />
        <Card.ImgOverlay className="text-white h-100 d-flex flex-column justify-content-end">
          <div className='Card_Info'>
            { props.staffPick &&
            <div className="Card_Info_Item">
              <p>Staff pick</p>
            </div>
            }
            { props.recommended &&
            <div className="Card_Info_Item">
              <p>Recommended</p>
            </div>
            }
          </div>
          <img src={playIcon} className="play-icon cursor-pointer" alt="" onClick={() => openVideoModal({...props.video, isAdmin: true})} />
          <div>
            <Stack direction="horizontal" gap={4} className="justify-content-center">
              <div>
              </div>
            </Stack>
          </div>
        </Card.ImgOverlay>
      </Card>
    </Col>
  )
}

export default AdminRejectedVideoCard

AdminRejectedVideoCard.propTypes = {
  video: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  socialNet: PropTypes.string.isRequired,
  likes: PropTypes.number.isRequired,
  videoName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string,
  country: PropTypes.string,
  city: PropTypes.string,
  age: PropTypes.string,
  mobile: PropTypes.string,
  image: PropTypes.string,
  staffPick: PropTypes.bool,
  recommended: PropTypes.bool,
  description: PropTypes.string.isRequired,
  videoSrc: PropTypes.string.isRequired,
  voted: PropTypes.bool,
  show: PropTypes.bool,
  pendingVideo: PropTypes.func,
  isAdmin: PropTypes.bool,
}
