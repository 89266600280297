import { Button, Col, Container, Dropdown, Row, Spinner } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import InputFile from '../../components/InputFile'
import { getImageString, getVideoString, handleInputFile } from '../../utils/InputFile'
import { useContext, useEffect, useMemo, useState } from 'react'
import PickImg from '../../components/PickImg'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { AppContext } from '../../context'
import { getCompetitionsDetail, patchCompetition } from '../../api/competitions'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import TextInputEditor from '../../components/TextInputEditor'
import pickImage from '../../assets/landing/addImage.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { URL as API_URL } from '../../constants'


const AdminEditCompetition = () => {
  const { token } = useContext(AppContext)
  const { competition_id } = useParams()
  const [mediaURL, setMediaURL] = useState('')
  const [mediaMobileURL, setMediaMobileURL] = useState('')
  const [frame1URL, setFrame1URL] = useState('')
  const [frame2URL, setFrame2URL] = useState('')
  const [saveState, setSaveState] = useState('')
  const [mainIcon, setMainIcon] = useState('')
  const [secondaryIcon, setSecondaryIcon] = useState('')
  const [rankingIcon, setRankingIcon] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const socialMediaList = ['TikTok', 'Instagram', 'Youtube']


  const formik = useFormik({
    initialValues: {
      name: '',
      title: '',
      subtitle: '',
      background: null,
      background_mobile: null,
      background_video_youtube: '',
      background_video_youtube_mobile: '',
      explicative_video: '',
      description: '',
      termsAndConditions: '',
      startDate: '',
      endDate: '',
      is_active: true,
      is_upload_videos_enabled: true,
      lucky_winner_title: '',
      selected_winner_title: '',
      leaderboard_winners_title: '',
      is_active_lucky_winner_home: false,
      is_active_lucky_winner_winners: false,
      is_active_selected_winner: true,
      is_active_leaderboard_winners: true,
      leaderboard_winners_amount: 3,
      lucky_winner_subtitle: '',
      selected_winner_subtitle: '',
      leaderboard_winners_subtitle: '',
      selected_icon: null,
      lucky_winner_icon: null,
      ranking_icon: null,
      example_title: '',
      example_participant_name: '',
      example_description: '',
      example_video_link: '',
      example_hosted_in: socialMediaList[0],
      video_frame_1: null,
      video_frame_2: null,
      leaderboard_winners_title2: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Competition name is required')
        .max(4, 'Max. 4 characters'),
      title: Yup.string()
        .required('Competition title is required')
        .max(100, 'Max. 100 characters'),
      subtitle: Yup.string()
        .required('Competition subtitle is required')
        .max(100, 'Max. 100 characters'),
      selected_winner_title: Yup.string()
        .required('Main prize title is required')
        .max(50, 'Max. 50 characters'),
      lucky_winner_title: Yup.string()
        .max(50, 'Max. 50 characters'),
      leaderboard_winners_title: Yup.string()
        .required('Title for ranking section is required')
        .max(50, 'Max. 50 characters'),
      selected_winner_subtitle: Yup.string()
        .required('Main prize subtitle is required')
        .max(250, 'Max. 250 characters'),
      lucky_winner_subtitle: Yup.string()
        .max(250, 'Max. 250 characters'),
      leaderboard_winners_subtitle: Yup.string()
        .required('Subtitle for ranking section is required')
        .max(250, 'Max. 250 characters'),
      leaderboard_winners_title2: Yup.string()
        .required('Title for leaderboard section is required')
        .max(50, 'Max. 50 characters'),
      description: Yup.string()
        .required('Description is required')
        .max(400, 'Max. 400 characters'),
      startDate: Yup.date().required('Start date is required'),
      endDate: Yup.date()
        .required('End date is required')
        .min(Yup.ref('startDate'), 'End date must be after start date'),
      termsAndConditions: Yup.string()
        .required('Terms and conditions are required'),
      explicative_video: Yup.string().required('Video link is required'),
    }),
    validate: async ({ termsAndConditions, background, background_mobile, background_video_youtube, background_video_youtube_mobile, selected_icon, ranking_icon, example_video_link, example_hosted_in, video_frame_1, video_frame_2 }) => {
      const errors = {}
      if (example_video_link) {
        example_video_link = await parseUrl(example_video_link)
      }
      let checkLink
      if (example_hosted_in === 'Instagram') {
        const instReg =
          /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_.]+)/
        checkLink = example_video_link.match(instReg)
      } else if (example_hosted_in === 'Youtube') {
        const YTReg =
          /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/
        checkLink = example_video_link.match(YTReg)
      } else if (example_hosted_in === 'TikTok') {
        const TTReg =
          /\bhttps?:\/\/(?:m|www|vm)\.tiktok\.com\/\S*?\b(?:(?:(?:usr|v|embed|video)\/|\?shareId=|&item_id=)(\d+)|(?=\w{7})(\w*?[A-Z\d]\w*)(?=\s|\/$))\b/
        checkLink = example_video_link.match(TTReg)
      }
      if (!checkLink) {
        errors.example_video_link = 'Please ensure the link matches your selected social network.'
      }
      if (!background && !background_mobile && !background_video_youtube && !background_video_youtube_mobile) {
        errors.background = 'At least one of the 4 options for background is required'
      }
      if (!selected_icon) {
        errors.selected_icon = 'Main prize icon is required'
      }
      if (!ranking_icon) {
        errors.ranking_icon = 'A ranking section Icon is required'
      }
      if (!video_frame_1) {
        errors.video_frame_1 = 'Video frame 1 is required'
      }
      if (!video_frame_2) {
        errors.video_frame_2 = 'Video frame 2 is required'
      }
      if (termsAndConditions === '<p><br></p>' || termsAndConditions.startsWith('<p><br></p>')) {
        errors.termsAndConditions = 'Terms and conditions are required'
      }

      return errors
    },
    onSubmit: async ({
      name,
      title,
      subtitle,
      background,
      background_mobile,
      background_video_youtube,
      background_video_youtube_mobile,
      explicative_video,
      description,
      termsAndConditions,
      startDate,
      endDate,
      is_active,
      is_upload_videos_enabled,
      lucky_winner_title,
      selected_winner_title,
      leaderboard_winners_title,
      lucky_winner_subtitle,
      selected_winner_subtitle,
      leaderboard_winners_subtitle,
      is_active_lucky_winner_home,
      is_active_lucky_winner_winners,
      is_active_selected_winner,
      is_active_leaderboard_winners,
      selected_icon,
      lucky_winner_icon,
      ranking_icon,
      leaderboard_winners_amount,
      example_title,
      example_participant_name,
      example_description,
      example_video_link,
      example_hosted_in,
      video_frame_1,
      video_frame_2,
      leaderboard_winners_title2,
    }) => {
      setIsLoading(true)
      const formdata = new FormData()
      formdata.append('name', name)
      formdata.append('title', title)
      formdata.append('subtitle', subtitle)
      formdata.append('explanation', description)
      formdata.append('start_date', startDate)
      formdata.append('end_date', endDate)
      if (background instanceof File) {
        formdata.append('background', background)
      } else if(background === null){
        formdata.append('background', '')
      }
      if (background_mobile instanceof File) {
        formdata.append('background_mobile', background_mobile)
      } else if(background_mobile === null){
        formdata.append('background_mobile', '')
      }
      formdata.append('background_video_youtube', background_video_youtube)
      formdata.append('background_video_youtube_mobile', background_video_youtube_mobile)
      formdata.append('explicative_video', explicative_video)
      if (selected_icon instanceof File) {
        formdata.append('selected_icon', selected_icon)
      }
      if (lucky_winner_icon instanceof File) {
        formdata.append('lucky_winner_icon', lucky_winner_icon)
      }
      if (ranking_icon instanceof File) {
        formdata.append('ranking_icon', ranking_icon)
      }
      formdata.append('leaderboard_winners_amount', leaderboard_winners_amount)
      formdata.append('terms_and_conditions', termsAndConditions)
      formdata.append('lucky_winner_title', lucky_winner_title)
      formdata.append('selected_winner_title', selected_winner_title)
      formdata.append('leaderboard_winners_title', leaderboard_winners_title)
      formdata.append('lucky_winner_subtitle', lucky_winner_subtitle)
      formdata.append('selected_winner_subtitle', selected_winner_subtitle)
      formdata.append('leaderboard_winners_subtitle', leaderboard_winners_subtitle)
      formdata.append('is_active_lucky_winner_home', is_active_lucky_winner_home.toString())
      formdata.append('is_active_lucky_winner_winners', is_active_lucky_winner_winners.toString())
      formdata.append('is_active_selected_winner', is_active_selected_winner.toString())
      formdata.append('is_active_leaderboard_winners', is_active_leaderboard_winners.toString())
      formdata.append('is_active', is_active.toString())
      formdata.append('is_upload_videos_enabled', is_upload_videos_enabled.toString())
      formdata.append('example_title', example_title)
      formdata.append('example_participant_name', example_participant_name)
      formdata.append('example_description', example_description)
      formdata.append('example_video_link', example_video_link)
      formdata.append('example_hosted_in', example_hosted_in)
      formdata.append('leaderboard_winners_title2', leaderboard_winners_title2)
      if (video_frame_1 instanceof File) {
        formdata.append('video_frame_1', video_frame_1)
      }
      if (video_frame_2 instanceof File) {
        formdata.append('video_frame_2', video_frame_2)
      }
      const { ok } = await patchCompetition(formdata, token, competition_id)
      setIsLoading(false)

      if (!ok) {
        setSaveState('error')
      } else {
        setSaveState('success')
      }
      setTimeout(() => {
        setSaveState('')
      }, 5000)
    }
  })

  const { data: competitionsDetail } = useQuery(
    ['getCompetitions', competition_id, token],
    () => {
      if (competition_id) {
        return getCompetitionsDetail(competition_id, token)
      }
    },
    {
      initialData: {
        data: {
          name: '',
        },
        ok: false,
      },
    }
  )

  const clearVideo = (e) => {
    e.preventDefault()
    setMediaURL('')
    formik.setFieldValue('background', null)
  }

  const clearVideoMobile = (e) => {
    e.preventDefault()
    setMediaMobileURL('')
    formik.setFieldValue('background_mobile', null)
  }

  const clearFrame1 = (e) => {
    e.preventDefault()
    setFrame1URL('')
    formik.setFieldValue('video_frame_1', null)
  }
  const clearFrame2 = (e) => {
    e.preventDefault()
    setFrame2URL('')
    formik.setFieldValue('video_frame_2', null)
  }

  const clearMainIcon = (e) => {
    e.preventDefault()
    setMainIcon('')
    formik.setFieldValue('selected_icon', null)
  }

  const clearSecondaryIcon = (e) => {
    e.preventDefault()
    setSecondaryIcon('')
    formik.setFieldValue('lucky_winner_icon', null)
  }

  const clearRankingIcon = (e) => {
    e.preventDefault()
    setRankingIcon('')
    formik.setFieldValue('ranking_icon', null)
  }

  const handleDiscardChanges = () => {
    formik.setValues({
      name: competitionsDetail.data.name,
      title: competitionsDetail.data.title,
      subtitle: competitionsDetail.data.subtitle ?? '',
      background: competitionsDetail.data.background,
      background_mobile: competitionsDetail.data.background_mobile ?? '',
      background_video_youtube: competitionsDetail.data.background_video_youtube ?? '',
      background_video_youtube_mobile: competitionsDetail.data.background_video_youtube_mobile ?? '',
      explicative_video: competitionsDetail.data.explicative_video ?? '',
      description: competitionsDetail.data.explanation,
      termsAndConditions: competitionsDetail.data.terms_and_conditions,
      startDate: competitionsDetail.data.start_date,
      endDate: competitionsDetail.data.end_date,
      is_active: competitionsDetail.data.is_active,
      is_upload_videos_enabled: competitionsDetail.data.is_upload_videos_enabled,
      lucky_winner_title: competitionsDetail.data.lucky_winner_title,
      selected_winner_title: competitionsDetail.data.selected_winner_title,
      leaderboard_winners_title: competitionsDetail.data.leaderboard_winners_title,
      lucky_winner_subtitle: competitionsDetail.data.lucky_winner_subtitle ?? '',
      selected_winner_subtitle: competitionsDetail.data.selected_winner_subtitle ?? '',
      leaderboard_winners_subtitle: competitionsDetail.data.leaderboard_winners_subtitle ?? '',
      is_active_lucky_winner_home: competitionsDetail.data.is_active_lucky_winner_home,
      is_active_lucky_winner_winners: competitionsDetail.data.is_active_lucky_winner_winners,
      is_active_selected_winner: competitionsDetail.data.is_active_selected_winner,
      is_active_leaderboard_winners: competitionsDetail.data.is_active_leaderboard_winners,
      selected_icon: competitionsDetail.data.selected_icon ?? '',
      lucky_winner_icon: competitionsDetail.data.lucky_winner_icon ?? '',
      ranking_icon: competitionsDetail.data.ranking_icon ?? '',
      leaderboard_winners_amount: competitionsDetail.data.leaderboard_winners_amount ?? '',
      example_title: competitionsDetail.data.video_example.title ?? '',
      example_participant_name: competitionsDetail.data.video_example.participant_name ?? '',
      example_description: competitionsDetail.data.video_example.description ?? '',
      example_video_link: competitionsDetail.data.video_example.video_link ?? '',
      example_hosted_in: competitionsDetail.data.video_example.hosted_in ?? '',
      video_frame_1: competitionsDetail.data.video_frame_1 ?? '',
      video_frame_2: competitionsDetail.data.video_frame_2 ?? '',
      leaderboard_winners_title2: competitionsDetail.data.leaderboard_winners_title2 ?? '',
    })
    setMediaURL(!competitionsDetail.data.background ? '' : competitionsDetail.data.background.startsWith('http') ? competitionsDetail.data.background : API_URL + competitionsDetail.data.background)
    setMediaMobileURL(!competitionsDetail.data.background_mobile ? '' : competitionsDetail.data.background_mobile.startsWith('http') ? competitionsDetail.data.background_mobile : API_URL + competitionsDetail.data.background_mobile)
    setFrame1URL(!competitionsDetail.data.video_frame_1 ? '' : competitionsDetail.data.video_frame_1.startsWith('http') ? competitionsDetail.data.video_frame_1 : API_URL + competitionsDetail.data.video_frame_1)
    setFrame2URL(!competitionsDetail.data.video_frame_2 ? '' : competitionsDetail.data.video_frame_2.startsWith('http') ? competitionsDetail.data.video_frame_2 : API_URL + competitionsDetail.data.video_frame_2)
    setMainIcon(!competitionsDetail.data.selected_icon ? '' : competitionsDetail.data.selected_icon.startsWith('http') ? competitionsDetail.data.selected_icon : API_URL + competitionsDetail.data.selected_icon)
    setSecondaryIcon(!competitionsDetail.data.lucky_winner_icon ? '' : competitionsDetail.data.lucky_winner_icon.startsWith('http') ? competitionsDetail.data.lucky_winner_icon : API_URL + competitionsDetail.data.lucky_winner_icon)
    setRankingIcon(!competitionsDetail.data.ranking_icon ? '' : competitionsDetail.data.ranking_icon.startsWith('http') ? competitionsDetail.data.ranking_icon : API_URL + competitionsDetail.data.ranking_icon)
  }
  useEffect(() => {
    if (!competitionsDetail.ok) return
    formik.setValues({
      name: competitionsDetail.data.name,
      title: competitionsDetail.data.title,
      subtitle: competitionsDetail.data.subtitle ?? '',
      background: competitionsDetail.data.background,
      background_mobile: competitionsDetail.data.background_mobile ?? '',
      background_video_youtube: competitionsDetail.data.background_video_youtube ?? '',
      background_video_youtube_mobile: competitionsDetail.data.background_video_youtube_mobile ?? '',
      explicative_video: competitionsDetail.data.explicative_video ?? '',
      description: competitionsDetail.data.explanation,
      termsAndConditions: competitionsDetail.data.terms_and_conditions,
      startDate: competitionsDetail.data.start_date,
      endDate: competitionsDetail.data.end_date,
      is_active: competitionsDetail.data.is_active,
      is_upload_videos_enabled: competitionsDetail.data.is_upload_videos_enabled,
      lucky_winner_title: competitionsDetail.data.lucky_winner_title,
      selected_winner_title: competitionsDetail.data.selected_winner_title,
      leaderboard_winners_title: competitionsDetail.data.leaderboard_winners_title,
      lucky_winner_subtitle: competitionsDetail.data.lucky_winner_subtitle ?? '',
      selected_winner_subtitle: competitionsDetail.data.selected_winner_subtitle ?? '',
      leaderboard_winners_subtitle: competitionsDetail.data.leaderboard_winners_subtitle ?? '',
      is_active_lucky_winner_home: competitionsDetail.data.is_active_lucky_winner_home,
      is_active_lucky_winner_winners: competitionsDetail.data.is_active_lucky_winner_winners,
      is_active_selected_winner: competitionsDetail.data.is_active_selected_winner,
      is_active_leaderboard_winners: competitionsDetail.data.is_active_leaderboard_winners,
      selected_icon: competitionsDetail.data.selected_icon ?? '',
      lucky_winner_icon: competitionsDetail.data.lucky_winner_icon ?? '',
      ranking_icon: competitionsDetail.data.ranking_icon ?? '',
      leaderboard_winners_amount: competitionsDetail.data.leaderboard_winners_amount ?? '',
      example_title: competitionsDetail.data.video_example.title ?? '',
      example_participant_name: competitionsDetail.data.video_example.participant_name ?? '',
      example_description: competitionsDetail.data.video_example.description ?? '',
      example_video_link: competitionsDetail.data.video_example.video_link ?? '',
      example_hosted_in: competitionsDetail.data.video_example.hosted_in ?? '',
      video_frame_1: competitionsDetail.data.video_frame_1 ?? '',
      video_frame_2: competitionsDetail.data.video_frame_2 ?? '',
      leaderboard_winners_title2: competitionsDetail.data.leaderboard_winners_title2 ?? '',
    })
    setMediaURL(!competitionsDetail.data.background ? '' : competitionsDetail.data.background.startsWith('http') ? competitionsDetail.data.background : API_URL + competitionsDetail.data.background)
    setMediaMobileURL(!competitionsDetail.data.background_mobile ? '' : competitionsDetail.data.background_mobile.startsWith('http') ? competitionsDetail.data.background_mobile : API_URL + competitionsDetail.data.background_mobile)
    setFrame1URL(!competitionsDetail.data.video_frame_1 ? '' : competitionsDetail.data.video_frame_1.startsWith('http') ? competitionsDetail.data.video_frame_1 : API_URL + competitionsDetail.data.video_frame_1)
    setFrame2URL(!competitionsDetail.data.video_frame_2 ? '' : competitionsDetail.data.video_frame_2.startsWith('http') ? competitionsDetail.data.video_frame_2 : API_URL + competitionsDetail.data.video_frame_2)
    setMainIcon(!competitionsDetail.data.selected_icon ? '' : competitionsDetail.data.selected_icon.startsWith('http') ? competitionsDetail.data.selected_icon : API_URL + competitionsDetail.data.selected_icon)
    setSecondaryIcon(!competitionsDetail.data.lucky_winner_icon ? '' : competitionsDetail.data.lucky_winner_icon.startsWith('http') ? competitionsDetail.data.lucky_winner_icon : API_URL + competitionsDetail.data.lucky_winner_icon)
    setRankingIcon(!competitionsDetail.data.ranking_icon ? '' : competitionsDetail.data.ranking_icon.startsWith('http') ? competitionsDetail.data.ranking_icon : API_URL + competitionsDetail.data.ranking_icon)

  }, [competitionsDetail.data])

  const parseUrl = async (url) => {
    if (url.includes('youtu')) {
      const youtubeRegex =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
      let match = url.match(youtubeRegex)

      url =
        match && match[2].length === 11
          ? 'https://www.youtube.com/embed/' + match[2]
          : url
    }
    if (url.includes('tiktok')) {
      const tiktokRegex =
        /https?:\/\/(?:m|www|vt|vm)\.tiktok\.com\b\S*?(?:\/(?:use?r|v|embed|video)\/|\bshareId=)(\d+)\b/

      const tiktokRegex2 =
        /https?:\/\/(?:m|www|vt|vm)\.tiktok\.com\/([a-zA-Z0-9]{9})/

      let match = url.match(tiktokRegex)

      let videoId = null

      if (!match) {
        const matchCode = url.match(tiktokRegex2)
        if (matchCode) {
          if (url.includes('vm.tiktok'))
            url = url.replace('vm.tiktok', 'vt.tiktok')
          const response = await axios.get(
            `https://www.tiktok.com/oembed?url=${url}`
          )

          videoId = response.data.embed_product_id
        }
      } else videoId = match[1]

      url = videoId ? 'https://www.tiktok.com/embed/' + videoId : url
    }
    return url
  }

  const YTReg =
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/

  const ytRegexForValidationShowVideo =
    /(?:https?:\/\/(?:www\.)?youtube\.com\/embed\/|https?:\/\/youtu\.be\/)([\w-]+)/

  const handleValidateYoutubeUrl = async (name, value) => {
    const url = await parseUrl(value)
    if (!url.match(YTReg)) {
      return formik.setFieldError(name, 'This link is not a valid YouTube video')
    }
    formik.setFieldError(name, '')
    formik.setFieldValue(name, url)
  }

  const handleFilter = (value) => {
    formik.setFieldValue('example_hosted_in', value)
  }

  const validExplicativeVideo = useMemo(() => (
    ytRegexForValidationShowVideo.test(formik.values.explicative_video)
  ), [formik.values.explicative_video])

  const validBackgroundVideoYoutube = useMemo(() => (
    ytRegexForValidationShowVideo.test(formik.values.background_video_youtube)
  ), [formik.values.background_video_youtube])

  const validBackgroundVideoYoutubeMobile = useMemo(() => (
    ytRegexForValidationShowVideo.test(formik.values.background_video_youtube_mobile)
  ), [formik.values.background_video_youtube_mobile])

  return (
    <>
      <Container fluid className="my-5">
        <Form className="w-100 my-5" onSubmit={formik.handleSubmit} >
          {competitionsDetail.ok &&
            <div>
              <h4 className='pt-4'>Editing competition: {competitionsDetail.data.name}</h4>
            </div>
          }
          <Form.Check
            type="switch"
            id="custom-switch"
            name="is_active"
            label="Activate/Deactivate competition"
            checked={formik.values.is_active}
            className='d-flex justify-content-end my-3 gap-3'
            onChange={formik.handleChange}
          />
          <Form.Group
            className="position-relative mb-4 fw-bold"
            controlId="competitionName"
          >
            <Form.Label>Competition name</Form.Label>
            <p
              className="d-inline ms-2 text-6 text_Secondary "
            >
              (Ex: 2024)
            </p>
            <Form.Control
              type="string"
              placeholder="Enter competition name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              isInvalid={formik.errors.name}
            />
            <p
              className={`position-absolute end-0 mt-1 text-6 ${formik.values.name.length > 4 ? 'text-red' : ''}`}
            >
              {formik.values.name.length}/4
            </p>
            <Form.Control.Feedback
              className="position-absolute mt-1 text-5 Input__Error"
              type="invalid"
            >
              {formik.errors.name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="position-relative mb-4 fw-bold"
            controlId="competitionTitle"
          >
            <Form.Label>Competition title</Form.Label>
            <p
              className="d-inline ms-2 text-6 text_Secondary "
            >
              (Banner title)
            </p>
            <Form.Control
              type="string"
              placeholder="Enter competition title"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              isInvalid={formik.errors.title}
            />
            <p
              className={`position-absolute end-0 mt-1 text-6 ${formik.values.title.length > 100 ? 'text-red' : ''}`}
            >
              {formik.values.title.length}/100
            </p>
            <Form.Control.Feedback
              className="position-absolute mt-1 text-5 Input__Error"
              type="invalid"
            >
              {formik.errors.title}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="position-relative mb-4 fw-bold"
            controlId="competitionSubtitle"
          >
            <Form.Label>Competition subtitle <span className='text-5' style={{ 'fontWeight': 'normal' }}>(for Competition/Past winners section)</span></Form.Label>
            <Form.Control
              type="string"
              placeholder="Enter competition subtitle"
              name="subtitle"
              value={formik.values.subtitle}
              onChange={formik.handleChange}
              isInvalid={formik.errors.subtitle}
            />
            <p
              className={`position-absolute end-0 mt-1 text-6 ${formik.values.subtitle.length > 100 ? 'text-red' : ''}`}
            >
              {formik.values.subtitle.length}/100
            </p>
            <Form.Control.Feedback
              className="position-absolute mt-1 text-5 Input__Error"
              type="invalid"
            >
              {formik.errors.subtitle}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="position-relative mb-4 fw-bold"
            controlId="description"
          >
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter a brief description of what the competition was based on..."
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              isInvalid={formik.errors.description}
            />
            <p
              className={`position-absolute end-0 mt-1 text-6 ${formik.values.description.length > 400 ? 'text-red' : ''}`}
            >
              {formik.values.description.length}/400
            </p>
            <Form.Control.Feedback
              className="position-absolute mt-1 Input__Error"
              type="invalid"
            >
              {formik.errors.description}
            </Form.Control.Feedback>
          </Form.Group>
          <Row>
            <Col>
              <Form.Group className="position-relative mb-4 fw-bold" controlId="start_date">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  name="startDate"
                  value={formik.values.startDate}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.startDate}
                />
                <Form.Control.Feedback className="position-absolute mt-1 text-5 Input__Error" type="invalid">
                  {formik.errors.startDate}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="position-relative mb-4 fw-bold" controlId="end_date">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  name="endDate"
                  value={formik.values.endDate}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.endDate}
                />
                <Form.Control.Feedback className="position-absolute mt-1 text-5 Input__Error" type="invalid">
                  {formik.errors.endDate}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group
            className="position-relative mb-5 fw-bold"
            controlId="description"
          >
            <Form.Label>Terms & conditions</Form.Label>
            <TextInputEditor
              value={formik.values.termsAndConditions}
              setValue={(newValue) => {
                formik.setFieldValue('termsAndConditions', newValue)
              }}
            />
            {formik.errors.termsAndConditions && (
              <div className="position-absolute my-2 fw-bold text-5 text-red Input__Error">
                {formik.errors.termsAndConditions}
              </div>
            )}
          </Form.Group>

          <hr className='mt-5' />
          <h4 className='mt-4'>Background section</h4>
          {formik.errors.background && (
            <div className="position-absolute my-2 fw-bold text-4 text-red Input__Error">
              {formik.errors.background}
            </div>
          )}
          <h5 className='mt-5 mb-3' style={{ 'color': 'gray', 'textDecoration': 'underline', 'fontWeight': 'normal' }}>Banner background video section:</h5>
          <Form.Group
            className="mb-4 fw-bold"
            controlId="background_video_youtube"
          >
            <Form.Label>Link to the YouTube background video</Form.Label>
            <p
              className="d-inline ms-2 text-6 text_Secondary "
            >
              (Desktop)
            </p>
            <div className='d-flex gap-1'>
              <Form.Control
                type="string"
                placeholder="Enter link"
                name="background_video_youtube"
                value={formik.values.background_video_youtube}
                onChange={formik.handleChange}
                isInvalid={formik.errors.background_video_youtube}
              />
              <Button
                variant={'rs-white-yellow'}
                onClick={() => handleValidateYoutubeUrl('background_video_youtube', formik.values.background_video_youtube)}
                style={{ 'borderRadius': '0 0.375rem 0.375rem 0' }}
              >
                Validate
              </Button>
              <Form.Control.Feedback
                className="position-absolute my-5 text-5 Input__Error"
                type="invalid"
              >
                {formik.errors.background_video_youtube}
              </Form.Control.Feedback>
            </div>
          </Form.Group>
          {validBackgroundVideoYoutube && (
            <iframe
              src={formik.values.background_video_youtube}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
              className='YT_preview_container'
            />
          )}
          <Form.Group
            className="mb-4 fw-bold"
            controlId="background_video_youtube_mobile"
          >
            <Form.Label>Link to the YouTube background video</Form.Label>
            <p
              className="d-inline ms-2 text-6 text_Secondary "
            >
              (Mobile)
            </p>
            <div className='d-flex gap-1'>
              <Form.Control
                type="string"
                placeholder="Enter link"
                name="background_video_youtube_mobile"
                value={formik.values.background_video_youtube_mobile}
                onChange={formik.handleChange}
                isInvalid={formik.errors.background_video_youtube_mobile}
              />
              <Button
                variant={'rs-white-yellow'}
                onClick={() => handleValidateYoutubeUrl('background_video_youtube_mobile', formik.values.background_video_youtube_mobile)}
                style={{ 'borderRadius': '0 0.375rem 0.375rem 0' }}
              >
                Validate
              </Button>
              <Form.Control.Feedback
                className="position-absolute my-5 text-5 Input__Error"
                type="invalid"
              >
                {formik.errors.background_video_youtube_mobile}
              </Form.Control.Feedback>
            </div>
          </Form.Group>
          {validBackgroundVideoYoutubeMobile && (
            <iframe
              src={formik.values.background_video_youtube_mobile}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
              className='YT_preview_container'
            />
          )}
          <h5 className='mt-5 mb-3' style={{ 'color': 'gray', 'textDecoration': 'underline', 'fontWeight': 'normal' }}>Banner background image section:</h5>
          <p
            className="d-inline text-5 text_Secondary mb-3"
          >
            (Desktop - Horizontal)
          </p>
          <InputFile
            label="Upload image"
            name="background"
            className='InputFile_Container'
            accept={[
              'image/apng',
              'image/png',
              'image/jpg',
              'image/jpeg',
              'image/webp',
              'image/gif',
            ]}
            onChange={(event) =>
              handleInputFile(
                {
                  event,
                  fileHandler: (file) => {
                    getImageString(file, (data, imageString) => {
                      formik.setFieldValue('background', data)
                      setMediaURL(imageString)
                    })
                  },
                  fileTypes: [
                    'video/mp4',
                    'video/quicktime',
                    'image/apng',
                    'image/png',
                    'image/jpg',
                    'image/jpeg',
                    'image/webp',
                    'image/gif',
                  ],
                  fileMaxSize: 50 //megabites
                })
            }
          >
            {mediaURL ? (
              <>
                <div style={{ 'width': '100%', 'maxWidth': '800px', 'position': 'relative', 'marginTop': '20px' }}>
                  <img
                    src={mediaURL}
                    alt="Background competition image"
                    className='VideoContainer'
                  />
                  <button type="button" className="btn-close CloseBtn" aria-label="Close" onClick={(e) => clearVideo(e)}></button>
                </div>
              </>
            ) : (
              <div className='EmptyVideoContainer' style={{ 'marginTop': '20px', 'cursor': 'pointer' }}>
                <div className='EmptyImage'>
                  <PickImg />
                </div>
                <div className='TextVideoContainer'>
                  <p className="mb-1 fw-bold">Add Image</p>
                  <p className="mb-1 fw-light">
                    Recommended dimensions 16:9
                  </p>
                  <p className="mb-1 fw-light">
                    Max. file size 50 MB
                  </p>
                </div>
              </div>
            )}
          </InputFile>
          <p
            className="d-inline text-5 text_Secondary my-3"
          >
            (Mobile - Vertical)
          </p>
          <InputFile
            label="Upload video"
            name="background_mobile"
            className='InputFile_Container'
            accept={[
              'image/apng',
              'image/png',
              'image/jpg',
              'image/jpeg',
              'image/webp',
              'image/gif',
            ]}
            onChange={(event) =>
              handleInputFile(
                {
                  event,
                  fileHandler: (file) => {
                    getImageString(file, (data, imageString) => {
                      formik.setFieldValue('background_mobile', data)
                      setMediaMobileURL(imageString)
                    })
                  },
                  fileTypes: [
                    'image/apng',
                    'image/png',
                    'image/jpg',
                    'image/jpeg',
                    'image/webp',
                    'image/gif',
                  ],
                  fileMaxSize: 50 //megabites
                })
            }
          >
            {mediaMobileURL ? (
              <>
                <div style={{ 'width': '100%', 'maxWidth': '350px', 'position': 'relative', 'marginTop': '20px' }}>
                  <img
                    src={mediaMobileURL}
                    alt="Background competition image"
                    className='VideoContainerMobile'
                  />
                  <button type="button" className="btn-close CloseBtn" aria-label="Close" onClick={(e) => clearVideoMobile(e)}></button>
                </div>
              </>
            ) : (
              <div className='EmptyVideoContainer' style={{ 'marginTop': '20px', 'cursor': 'pointer' }} >
                <div className='EmptyImage'>
                  <PickImg />
                </div>
                <div className='TextVideoContainer'>
                  <p className="mb-1 fw-bold">Add Image</p>
                  <p className="mb-1 fw-light">
                    Recommended dimensions 16:9
                  </p>
                  <p className="mb-1 fw-light">
                    Max. file size 50 MB
                  </p>
                </div>
              </div>
            )}
            {formik.errors.background_mobile && (
              <div className="position-absolute mt-0 text-5 text-red Input__Error">
                {formik.errors.background_mobile}
              </div>
            )}
          </InputFile>

          <hr className='mt-5' />
          <h5 className='mt-5 mb-3' style={{ 'color': 'gray', 'textDecoration': 'underline', 'fontWeight': 'normal' }}>Downloadable Frames Section for Competition Videos</h5>
          <InputFile
            label="Upload image frame 1"
            name="video_frame_1"
            className='InputFile_Container'
            accept={[
              'video/mp4',
              'video/quicktime',
            ]}
            onChange={(event) =>
              handleInputFile(
                {
                  event,
                  fileHandler: (file) => {
                    getVideoString(file, (data, videoString) => {
                      formik.setFieldValue('video_frame_1', data)
                      setFrame1URL(videoString)
                    })
                  },
                  fileTypes: [
                    'video/mp4',
                    'video/quicktime',
                  ],
                  fileMaxSize: 15 //megabites
                })
            }
          >
            {frame1URL ? (
              <>
                <div style={{ 'width': '100%', 'maxWidth': '800px', 'position': 'relative' }}>
                  <video
                    src={frame1URL}
                    controls
                    className='VideoContainer'
                  />
                  <button type="button" className="btn-close CloseBtn" aria-label="Close" onClick={(e) => clearFrame1(e)}></button>
                </div>
              </>
            ) : (
              <div className='EmptyVideoContainer' style={{ 'cursor': 'pointer' }}>
                <div className='EmptyImage'>
                  <PickImg />
                </div>
                <div className='TextVideoContainer'>
                  <p className="mb-1 fw-bold">Add Video Frame Horizontal</p>
                  <p
                    className="mb-1 fw-light"
                  >
                    (For Youtube)
                  </p>
                  <p className="mb-1 fw-light">
                    Max. file size 15 MB
                  </p>
                </div>
              </div>
            )}
            {formik.errors.video_frame_1 && (
              <div className="position-absolute mt-0 text-5 text-red fw-bold Input__Error">
                {formik.errors.video_frame_1}
              </div>
            )}
          </InputFile>
          <br />
          <InputFile
            label="Upload image frame 2"
            name="video_frame_2"
            className='InputFile_Container'
            accept={[
              'video/mp4',
              'video/quicktime',
            ]}
            onChange={(event) =>
              handleInputFile(
                {
                  event,
                  fileHandler: (file) => {
                    getVideoString(file, (data, videoString) => {
                      formik.setFieldValue('video_frame_2', data)
                      setFrame2URL(videoString)
                    })

                  },
                  fileTypes: [
                    'video/mp4',
                    'video/quicktime',
                  ],
                  fileMaxSize: 15 //megabites
                })
            }
          >
            {frame2URL ? (
              <>
                <div style={{ 'width': '100%', 'maxWidth': '350px', 'position': 'relative' }}>
                  <video
                    src={frame2URL}
                    controls
                    className='VideoContainerMobile'
                  />
                  <button type="button" className="btn-close CloseBtn" aria-label="Close" onClick={(e) => clearFrame2(e)}></button>
                </div>
              </>
            ) : (
              <div className='EmptyVideoContainer' style={{ 'cursor': 'pointer' }}>
                <div className='EmptyImage'>
                  <PickImg />
                </div>
                <div className='TextVideoContainer'>
                  <p className="mb-1 fw-bold">Add Video Frame Vertical</p>
                  <p
                    className="mb-1 fw-light"
                  >
                    (For Instagram & TikTok)
                  </p>
                  <p className="mb-1 fw-light">
                    Max. file size 15 MB
                  </p>
                </div>
              </div>
            )}
            {formik.errors.video_frame_2 && (
              <div className="position-absolute mt-0 text-5 text-red Input__Error fw-bold">
                {formik.errors.video_frame_2}
              </div>
            )}
          </InputFile>
          <hr className='mt-5' />
          <h5 className='mt-5 mb-3' style={{ 'color': 'gray', 'textDecoration': 'underline', 'fontWeight': 'normal' }}>Explanatory video section</h5>
          <Form.Group
            className="mb-4 fw-bold"
            controlId="explicative_video"
          >
            <Form.Label>Link to explanatory video</Form.Label>
            <p
              className="d-inline ms-2 text-6 text_Secondary "
            >
              (Youtube)
            </p>
            <div className='d-flex gap-1'>
              <Form.Control
                type="string"
                placeholder="Enter link"
                name="explicative_video"
                value={formik.values.explicative_video}
                onChange={formik.handleChange}
                isInvalid={formik.errors.explicative_video}
              />
              <Button
                variant={'rs-white-yellow'}
                onClick={() => handleValidateYoutubeUrl('explicative_video', formik.values.explicative_video)}
                style={{ 'borderRadius': '0 0.375rem 0.375rem 0' }}
              >
                Validate
              </Button>
              <Form.Control.Feedback
                className="position-absolute my-5 text-5 Input__Error"
                type="invalid"
              >
                {formik.errors.explicative_video}
              </Form.Control.Feedback>
            </div>
          </Form.Group>
          {validExplicativeVideo && (
            <iframe
              src={formik.values.explicative_video}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
              className='YT_preview_container'
            />
          )}
          <hr className='mt-5' />
          <h5 className='mt-5 mb-4' style={{ 'color': 'gray', 'textDecoration': 'underline', 'fontWeight': 'normal' }}>Example video section</h5>

          <Form.Group
            className="position-relative mb-4 fw-bold"
            controlId="example_title"
          >
            <Form.Label>Example video title</Form.Label>
            <Form.Control
              type="string"
              placeholder="Enter example video title"
              name="example_title"
              value={formik.values.example_title}
              onChange={formik.handleChange}
              isInvalid={formik.errors.example_title}
            />
            <Form.Control.Feedback
              className="position-absolute mt-0 text-5 Input__Error"
              type="invalid"
            >
              {formik.errors.example_title}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            className="position-relative mb-4 fw-bold"
            controlId="example_participant_name"
          >
            <Form.Label>Participant name for example video</Form.Label>
            <Form.Control
              type="string"
              placeholder="Participant name for example video"
              name="example_participant_name"
              value={formik.values.example_participant_name}
              onChange={formik.handleChange}
              isInvalid={formik.errors.example_participant_name}
            />
            <Form.Control.Feedback
              className="position-absolute mt-0 text-5 Input__Error"
              type="invalid"
            >
              {formik.errors.example_participant_name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            className="position-relative mb-4 fw-bold"
            controlId="example_description"
          >
            <Form.Label>Description for example video</Form.Label>
            <Form.Control
              type="string"
              placeholder="Enter description for example video"
              name="example_description"
              value={formik.values.example_description}
              onChange={formik.handleChange}
              isInvalid={formik.errors.example_description}
            />
            <Form.Control.Feedback
              className="position-absolute mt-0 text-5 Input__Error"
              type="invalid"
            >
              {formik.errors.example_description}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="mb-4 fw-bold"
            controlId="example_video_link"
          >
            <Form.Label>Link to example video</Form.Label>
            <div className='d-flex gap-1'>
              <Form.Control
                type="string"
                placeholder="Enter link"
                name="example_video_link"
                value={formik.values.example_video_link}
                onChange={formik.handleChange}
                isInvalid={formik.errors.example_video_link}
              />
              <Button
                variant={'rs-white-yellow'}
                onClick={() => handleValidateYoutubeUrl('example_video_link', formik.values.example_video_link)}
                style={{ 'borderRadius': '0 0.375rem 0.375rem 0' }}
              >
                Validate
              </Button>
              <Form.Control.Feedback
                className="position-absolute my-5 text-5 Input__Error"
                type="invalid"
              >
                {formik.errors.example_video_link}
              </Form.Control.Feedback>
            </div>
          </Form.Group>
          <Form.Group
            className="position-relative mb-4 fw-bold"
            controlId="example_video_link"
          >
            <Form.Label className='mt-2'>Video posted on:</Form.Label>
            <Dropdown onSelect={handleFilter}>
              <Dropdown.Toggle variant="rs-white-yellow">
                {formik.values.example_hosted_in}{' '}
                <FontAwesomeIcon icon={faAngleDown} size="sm" className="ms-2" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {socialMediaList.map((socialMedia, index) => (
                  <Dropdown.Item key={index} eventKey={socialMedia}>
                    {socialMedia}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <hr className='my-5' />
          <h5 style={{ 'color': 'gray', 'textDecoration': 'underline', 'fontWeight': 'normal' }}>Prices section</h5>
          <div className='PricesSections py-4 my-4'>
            <Row className="Prizes1 d-flex align-items-center px-5">
              <Col>
                <Form.Group
                  className="position-relative mb-4 fw-bold"
                  controlId="selected_winner_title"
                >
                  <Form.Label>Main prize title</Form.Label>
                  <Form.Control
                    type="string"
                    placeholder="Enter main prize title"
                    name="selected_winner_title"
                    value={formik.values.selected_winner_title}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.selected_winner_title}
                  />
                  <Form.Control.Feedback
                    className="position-absolute mt-0 text-5 Input__Error"
                    type="invalid"
                  >
                    {formik.errors.selected_winner_title}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col>
                <InputFile
                  label="Upload icon"
                  name="selected_icon"
                  accept={[
                    'image/svg+xml',
                    'image/png',
                    'image/webp',
                  ]}
                  onChange={(event) =>
                    handleInputFile(
                      {
                        event,
                        fileHandler: (file) => {
                          getImageString(file, (data, imageString) => {
                            formik.setFieldValue('selected_icon', data)
                            setMainIcon(imageString)
                          })
                        },
                        fileTypes: [
                          'image/svg+xml',
                          'image/png',
                          'image/webp',
                        ],
                        fileMaxSize: 15 //megabites
                      })
                  }
                >
                  {mainIcon ? (
                    <>
                      <div className='d-flex justify-content-center align-items-center gap-3'>
                        <img
                          src={mainIcon}
                          alt="Icon for secondary prize"
                          className='IconContainer'
                        />
                        <button type="button" className="btn-close CloseBtnIcons" aria-label="Close" onClick={(e) => clearMainIcon(e)} ></button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='d-flex gap-2'>
                        <div className='IconContainer'>
                          <img src={pickImage} alt="" className='imgIcon' />
                        </div>
                        <div className='EmptyIconContainer'>
                          <div className='TextVideoContainer'>
                            <p className="mb-1 fw-bold">Add an icon</p>
                            <p className="mb-1 fw-light">
                              Recommended format SVG/PNG
                            </p>
                            <p className="mb-1 fw-light">
                              Max. file size 15 MB
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {formik.errors.selected_icon && (
                    <div className="position-absolute mt-0 text-5 fw-bold text-red Input__Error">
                      {formik.errors.selected_icon}
                    </div>
                  )}
                </InputFile>
              </Col>
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  name="is_active_selected_winner"
                  label="Activate/Deactivate"
                  checked={formik.values.is_active_selected_winner}
                  onChange={formik.handleChange}
                />
              </Col>
            </Row>
            <Row className='px-5'>
              <Form.Group
                className="position-relative mb-4 fw-bold"
                controlId="selected_winner_subtitle"
              >
                <Form.Label style={{ 'fontWeight': 'normal' }}>Main subtitle prize</Form.Label>
                <p
                  className="d-inline ms-2 text-6 text_Secondary "
                >
                  (Ex: Lucky draw)
                </p>
                <TextInputEditor
                  value={formik.values.selected_winner_subtitle}
                  setValue={(newValue) => {
                    formik.setFieldValue('selected_winner_subtitle', newValue)
                  }}
                />
                {formik.errors.selected_winner_subtitle && (
                  <div className="position-absolute mt-0 text-5 text-red Input__Error">
                    {formik.errors.selected_winner_subtitle}
                  </div>
                )}
              </Form.Group>
            </Row>
          </div>
          <div className='PricesSections py-4 my-4'>
            <Row className="Prizes1 d-flex align-items-center px-5">
              <Col>
                <Form.Group
                  className="position-relative mb-4 fw-bold"
                  controlId="lucky_winner_title"
                >
                  <Form.Label>Secondary prize title</Form.Label>
                  <p
                    className="d-inline ms-2 text-6 text_Secondary "
                  >
                    (Ex: Lucky draw)
                  </p>
                  <Form.Control
                    type="string"
                    placeholder="Enter secondary prize title"
                    name="lucky_winner_title"
                    value={formik.values.lucky_winner_title}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.lucky_winner_title}
                  />
                  <Form.Control.Feedback
                    className="position-absolute mt-0 text-5 Input__Error"
                    type="invalid"
                  >
                    {formik.errors.lucky_winner_title}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col>
                <InputFile
                  label="Upload icon"
                  name="lucky_winner_icon"
                  accept={[
                    'image/svg+xml',
                    'image/png',
                    'image/webp',
                  ]}
                  onChange={(event) =>
                    handleInputFile(
                      {
                        event,
                        fileHandler: (file) => {
                          getImageString(file, (data, imageString) => {
                            formik.setFieldValue('lucky_winner_icon', data)
                            setSecondaryIcon(imageString)
                          })
                        },
                        fileTypes: [
                          'image/svg+xml',
                          'image/png',
                          'image/webp',
                        ],
                        fileMaxSize: 15 //megabites
                      })
                  }
                >
                  {secondaryIcon ? (
                    <>
                      <div className='d-flex justify-content-center align-items-center gap-3'>
                        <img
                          src={secondaryIcon}
                          alt="Icon for secondary prize"
                          className='IconContainer'
                        />
                        <button type="button" className="btn-close CloseBtnIcons" aria-label="Close" onClick={(e) => clearSecondaryIcon(e)} ></button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='d-flex gap-2'>
                        <div className='IconContainer'>
                          <img src={pickImage} alt="" className='imgIcon' />
                        </div>
                        <div className='EmptyIconContainer'>
                          <div className='TextVideoContainer'>
                            <p className="mb-1 fw-bold">Add an icon</p>
                            <p className="mb-1 fw-light">
                              Recommended format SVG/PNG
                            </p>
                            <p className="mb-1 fw-light">
                              Max. file size 15 MB
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </InputFile>
              </Col>
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  name="is_active_lucky_winner_home"
                  label="Show on home page"
                  checked={formik.values.is_active_lucky_winner_home}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  name="is_active_lucky_winner_winners"
                  label="Show on competitors winners page"
                  checked={formik.values.is_active_lucky_winner_winners}
                  onChange={formik.handleChange}
                />
              </Col>
            </Row>
            <Row className='px-5'>
              <Col>
                <Form.Group
                  className="position-relative mb-4 fw-bold"
                  controlId="lucky_winner_subtitle"
                >
                  <Form.Label style={{ 'fontWeight': 'normal' }}>Secondary subtitle prize</Form.Label>
                  <p
                    className="d-inline ms-2 text-6 text_Secondary "
                  >
                    (Ex: Lucky draw)
                  </p>
                  <TextInputEditor
                    value={formik.values.lucky_winner_subtitle}
                    setValue={(newValue) => {
                      formik.setFieldValue('lucky_winner_subtitle', newValue)
                    }}
                  />
                  {formik.errors.lucky_winner_subtitle && (
                    <div className="position-absolute mt-0 text-5 text-red Input__Error">
                      {formik.errors.lucky_winner_subtitle}
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className='PricesSections py-4 my-4'>
            <Row className="Prizes1 d-flex align-items-center px-5">
              <Col>
                <Form.Group
                  className="position-relative mb-4 fw-bold"
                  controlId="leaderboard_winners_title"
                >
                  <Form.Label>Ranking section title</Form.Label>
                  <Form.Control
                    type="string"
                    placeholder="Enter title for ranking section"
                    name="leaderboard_winners_title"
                    value={formik.values.leaderboard_winners_title}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.leaderboard_winners_title}
                  />
                  <Form.Control.Feedback
                    className="position-absolute mt-0 text-5 Input__Error"
                    type="invalid"
                  >
                    {formik.errors.leaderboard_winners_title}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="position-relative mb-4 fw-bold"
                  controlId="leaderboard_winners_title2"
                >
                  <Form.Label>Leaderboard section title</Form.Label>
                  <Form.Control
                    type="string"
                    placeholder="Enter title for leaderboard section"
                    name="leaderboard_winners_title2"
                    value={formik.values.leaderboard_winners_title2}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.leaderboard_winners_title2}
                  />
                  <Form.Control.Feedback
                    className="position-absolute mt-0 text-5 Input__Error"
                    type="invalid"
                  >
                    {formik.errors.leaderboard_winners_title2}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="Prizes1 d-flex align-items-center px-5">
              <Col>
                <InputFile
                  label="Upload icon"
                  name="ranking_icon"
                  accept={[
                    'image/svg+xml',
                    'image/png',
                    'image/webp',
                  ]}
                  onChange={(event) =>
                    handleInputFile(
                      {
                        event,
                        fileHandler: (file) => {
                          getImageString(file, (data, imageString) => {
                            formik.setFieldValue('ranking_icon', data)
                            setRankingIcon(imageString)
                          })
                        },
                        fileTypes: [
                          'image/svg+xml',
                          'image/png',
                          'image/webp',
                        ],
                        fileMaxSize: 15 //megabites
                      })
                  }
                >
                  {rankingIcon ? (
                    <>
                      <div className='d-flex justify-content-center align-items-center gap-3'>
                        <img
                          src={rankingIcon}
                          alt="Icon for prizes ranking prices"
                          className='IconContainer'
                        />
                        <button type="button" className="btn-close CloseBtnIcons" aria-label="Close" onClick={(e) => clearRankingIcon(e)} ></button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='d-flex gap-2'>
                        <div className='IconContainer'>
                          <img src={pickImage} alt="" className='imgIcon' />
                        </div>
                        <div className='EmptyIconContainer'>
                          <div className='TextVideoContainer'>
                            <p className="mb-1 fw-bold">Add an icon</p>
                            <p className="mb-1 fw-light">
                              Recommended format SVG/PNG
                            </p>
                            <p className="mb-1 fw-light">
                              Max. file size 15 MB
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {formik.errors.ranking_icon && (
                    <div className="position-absolute fw-bold mt-0 text-5 text-red Input__Error">
                      {formik.errors.ranking_icon}
                    </div>
                  )}
                </InputFile>
              </Col>
              <Col className="d-flex align-items-center">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  name="is_active_leaderboard_winners"
                  label="Activate/Deactivate"
                  checked={formik.values.is_active_leaderboard_winners}
                  onChange={formik.handleChange}
                />
              </Col>
            </Row>
            <Form.Group className='px-5 my-5'>
              <Form.Label>Number of competitors to show</Form.Label>
              <Form.Control
                placeholder="Enter amount of competitors to show"
                name="leaderboard_winners_amount"
                type='number'
                value={formik.values.leaderboard_winners_amount}
                onChange={formik.handleChange}
                isInvalid={formik.errors.leaderboard_winners_amount}
              />
            </Form.Group>
            <Form.Group
              className="position-relative px-5 mb-4 fw-bold"
              controlId="leaderboard_winners_subtitle"
            >
              <Form.Label style={{ 'fontWeight': 'normal' }}> Ranking subtitle section</Form.Label>
              <TextInputEditor
                value={formik.values.leaderboard_winners_subtitle}
                setValue={(newValue) => {
                  formik.setFieldValue('leaderboard_winners_subtitle', newValue)
                }}
              />
              {formik.errors.leaderboard_winners_subtitle && (
                <div className="position-absolute mt-0 text-5 text-red Input__Error">
                  {formik.errors.leaderboard_winners_subtitle}
                </div>
              )}
            </Form.Group>
          </div>
          {saveState !== '' &&
            <p className='m-3'>{saveState === 'success' ? 'Changes saved successfully' : 'Something went wrong'}</p>
          }
          <div className='d-flex justify-content-end gap-3'>
            <button type="button" className="BtnStyle btn btn-outline-secondary" onClick={handleDiscardChanges}>Discard changes</button>
            <button type="submit" className="BtnStyle btn btn-primary" >Save changes</button>
          </div>
          {isLoading ? (
            <div className="Loading_image_Competition">
              <Spinner animation="border" variant="warning" />
            </div>
          ) : (
            <></>
          )}
        </Form>
      </Container >
    </>
  )
}

export default AdminEditCompetition