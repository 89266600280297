import { Nav } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import AdminReviewVideos from './AdminReviewVideos'
import AdminAcceptedVideos from './AdminAcceptedVideos'
import AdminStaffPick from './AdminStaffPick'
import { useContext, useEffect, useState } from 'react'
import AdminRejectedVideos from './AdminRejectedVideos'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { AppContext } from '../../context'
import { patchAdminSetting } from '../../api/server'
import AdminRecommendedVideos from './AdminRecommendedVideos'

const AdminVideos = () => {
  const { token, serverInfo, setServerInfo } = useContext(AppContext)
  const [step, setStep] = useState(0)
  const [sendEmail, setSendEmail] = useState(serverInfo?.is_automated_emails_enabled ?? false)
  const [disabledSwitch, setDisabledSwitch] = useState(false)
  const componentsList = [
    <AdminReviewVideos key={1} />,
    <AdminAcceptedVideos key={2} />,
    <AdminStaffPick key={3} />,
    <AdminRecommendedVideos key={4} />,
    <AdminRejectedVideos key={5} />,
  ]

  useEffect(() => {
    setSendEmail(serverInfo?.is_automated_emails_enabled ?? false)
  }, [serverInfo])

  const toggleSendEmails = async () => {
    if (disabledSwitch) return null
    setDisabledSwitch(true)
    setSendEmail((prev) => !prev)

    const response = await patchAdminSetting(token, {
      is_automated_emails_enabled: !serverInfo?.is_automated_emails_enabled ?? false,
    })

    if (response.error) setSendEmail((prev) => !prev)
    else if (response) setServerInfo(response)
    
    setDisabledSwitch(false)
  }

  return (
    <Container fluid className="mt-5">
      <div
        className="AdminReviewVideos__Send_video_Mobile w-max-cont cursor-pointer"
        onClick={toggleSendEmails}
      >
        <FontAwesomeIcon icon={faEnvelope} className="text_primary" />
        <p className="mb-0 text_primary fw-normal">Send emails</p>
        <button className={`switch ${sendEmail ? 'active' : ''}`} />
      </div>
      <Nav
        variant="tabs"
        defaultActiveKey="review"
        className="mb-4 AdminVideos"
      >
        <Nav.Item>
          <Nav.Link
            className="w-max-cont"
            eventKey="review"
            onClick={() => setStep(0)}
          >
            Review application
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className="w-max-cont"
            eventKey="accepted"
            onClick={() => setStep(1)}
          >
            Accepted
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className="w-max-cont"
            eventKey="staff_pick"
            onClick={() => setStep(2)}
          >
            Staff pick
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className="w-max-cont"
            eventKey="recommended"
            onClick={() => setStep(3)}
          >
            Recommended
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className="w-max-cont"
            eventKey="rejected"
            onClick={() => setStep(4)}
          >
            Rejected
          </Nav.Link>
        </Nav.Item>
        <div className="AdminReviewVideos__Send_video me-2" onClick={toggleSendEmails}>
          <FontAwesomeIcon icon={faEnvelope} className="text_primary" />
          <p
            className="mb-0 text_primary fw-normal"
          >
            Send emails
          </p>
          <button className={`switch ${sendEmail ? 'active' : ''}`} />
        </div>
      </Nav>
      {componentsList[step]}
    </Container>
  )
}

export default AdminVideos
