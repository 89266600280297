import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import logo from '../assets/landing/rfds-rs-logo.png'
import Button from 'react-bootstrap/Button'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../context'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRightFromBracket,
  faHeart,
} from '@fortawesome/free-solid-svg-icons'
import { Modal } from 'react-bootstrap'
import NavDropdown from '../components/NavDropdown'
import { getCompetitions } from '../api/competitions'
import { useQuery } from 'react-query'
import VotesRemaining from '../components/VotesRemaining'

const Header = () => {
  const {
    token,
    setToken,
    competitionInfo,
    remainingVotes,
    openVideoModal,
    user,
  } = useContext(AppContext)
  const [showLogout, setShowLogout] = useState(false)
  const [ShowCollapse, setShowCollapse] = useState(false)
  const navigate = useNavigate()
  const [isMobile, setIsMobile] = useState(false)


  const { data: competitionsData } = useQuery(
    ['getCompetitions'],
    () => getCompetitions(),
    {
      initialData: {
        data: {
          competitions: [],
          current_page: 1,
          total_pages: 1,
        },
        ok: false,
      },
      refetchOnWindowFocus: false
    }
  )

  const getLastUpcomingCompetition = (competitionsData) => {
    const currentDate = new Date()

    const pastCompetitions = competitionsData.data.competitions.filter(
      (competition) => {
        const competitionEndDate = new Date(competition.end_date)
        return competitionEndDate < currentDate
      }
    )

    if (pastCompetitions.length > 0) {
      pastCompetitions.sort((a, b) =>
        new Date(b.end_date) - new Date(a.end_date)
      )

      const lastFinishedCompetition = pastCompetitions[0]
      return lastFinishedCompetition
    }

    return undefined
  }

  const lastFinishedCompetition = getLastUpcomingCompetition(competitionsData)
  const competition_id = lastFinishedCompetition?.id

  useEffect(() => {
    const checkIsMobile = () => {
      if (window.matchMedia('(max-width: 1199px)').matches) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }
    checkIsMobile()

    window.addEventListener('resize', checkIsMobile)

    return () => window.removeEventListener('resize', checkIsMobile)
  })

  const openShowLogout = () => {
    setShowLogout(true)
  }

  const closeShowLogout = () => {
    setShowLogout(false)
  }

  const handleLogout = async () => {
    navigate('/', { replace: true })
    setToken(null)
    setShowLogout(false)
  }

  // const handleOpenVideo = async () => {

  //   openVideoModal({
  //     hosted_in: competitionInfo.competition.video_example.hosted_in,
  //     video_link: competitionInfo.competition.video_example.video_link,
  //     name: competitionInfo.competition.video_example.title,
  //     description: competitionInfo.competition.video_example.description,
  //     user_name: competitionInfo.competition.video_example.participant_name,
  //     isExample: true,
  //   })
  // }
  const toggleShowCollapse = () =>
    isMobile ? setShowCollapse((prev) => !prev) : null

  return (
    <>
      <div className="w-100 position-fixed top-0 z-index-fixed">
        <div className="TopNavbar">
          <a
            href="https://www.rescueswag.com.au/"
            target="_blank"
            rel="noreferrer"
          >
            <u>GET 15% OFF YOUR RESCUE SWAG</u>
          </a>{' '}
          - USE CODE OUTBACK15
        </div>
        <Navbar expanded={ShowCollapse} expand="xl" bg="white" variant="light">
          <Container>
            <Link to="/" className="navbar-brand">
              <img
                src={logo}
                width={240}
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            </Link>
            <div className='ContainerHamLogo'>
              {competitionInfo && competitionInfo.state === 'ongoing' && (
                <>
                  <VotesRemaining />
                </>
              )}
              <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                onClick={toggleShowCollapse}
              />
            </div>
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-between"
            >
              <Nav className="nav-rs p-3 py-lg-0 align-items-center justify-content-center">
                {competitionInfo && competitionInfo.state !== 'finished' && (
                  <NavLink
                    to="/#how-to-enter"
                    className="nav-link"
                    onClick={toggleShowCollapse}
                  >
                    How to enter
                  </NavLink>
                )}
                <NavDropdown />
                <NavLink
                  to="/who-we-are"
                  className="nav-link"
                  onClick={toggleShowCollapse}
                >
                  Who We Are
                </NavLink>
                {competitionInfo && token && competitionInfo.state === 'ongoing' && (
                  <NavLink
                    to="/liked-videos"
                    className={({ isActive }) =>
                      `nav-link ${isActive ? 'border-bottom-bt-dark' : ''}`
                    }
                    onClick={toggleShowCollapse}
                  >
                    My votes
                  </NavLink>
                )}
                {(competitionInfo && competitionInfo.state === 'finished') && (
                  <NavLink
                    to={`/winners/${competitionInfo.competition.id}`}
                    className="nav-link"
                  >
                    Competition Winners
                  </NavLink>
                )}
                {competitionInfo && competitionInfo.state === 'ongoing' && (
                  <div className="Header__votes d-xl-flex HeartDesktop">
                    <div className="Header__votes_Heart">
                      <FontAwesomeIcon icon={faHeart} />
                      <p className="Header__votes_Heart_Number font_roboto_slab mb-0">
                        {remainingVotes}
                      </p>
                    </div>
                    <p className="Header__votes_Text font_roboto_slab mb-0">
                      Votes remaining today
                    </p>
                  </div>
                )}
              </Nav>
              {token ? (
                <>
                  <Button
                    variant="rs-white d-none d-xl-block"
                    onClick={openShowLogout}
                  >
                    <FontAwesomeIcon icon={faArrowRightFromBracket} />
                    <span className="ms-2">Log out</span>
                  </Button>
                  <Button
                    variant="rs-white-yellow d-xl-none"
                    onClick={openShowLogout}
                  >
                    <FontAwesomeIcon icon={faArrowRightFromBracket} />
                    <span className="ms-2">Log out</span>
                  </Button>
                </>
              ) : (competitionInfo && competitionInfo.state !== 'finished') ? (
                <NavLink
                  to="/register"
                  className="mb-2 mb-xl-0"
                  style={{ height: 'min-content' }}
                >
                  <Button id="Nav-Bar-Create-Account-Btn" variant="rs w-100 px-5">Get more votes!</Button>
                </NavLink>
              ): <></>}
            </Navbar.Collapse>
          </Container>

        </Navbar>
      </div>

      <div
        className={`Header__Background ${ShowCollapse ? 'Active' : ''}`}
        onClick={toggleShowCollapse}
      />

      <Modal
        centered
        // size="lg"
        size="sm"
        // dialogClassName="modal-60w"
        show={showLogout}
        onHide={closeShowLogout}
      >
        <Modal.Body className="p-5">
          <h3 className="text-center">Log out?</h3>
          <p className="mt-5">
            Your additional daily votes can only be used when you are logged in
            to your account
          </p>
          <div className="mt-5 d-flex justify-content-around">
            <Button variant="rs-white" onClick={closeShowLogout}>
              Cancel
            </Button>
            <Button variant="rs" onClick={handleLogout}>
              Log out
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Header
