import { NavLink } from 'react-router-dom'
import { getCompetitions } from '../api/competitions'
import { useQuery } from 'react-query'
import { useContext } from 'react'
import { AppContext } from '../context'

const AdminSideBar = () => {
  const { token, user } = useContext(AppContext)

  const { data } = useQuery(
    ['getCompetitions', token],
    () => getCompetitions(token),
    {
      initialData: {
        data: {
          competitions: [],
          current_page: 1,
          total_pages: 1,
        },
        ok: false,
      },
      enabled: token !== '' && (user?.user_serializer.is_superuser ?? false),
      refetchOnWindowFocus: false
    }
  )

  return (
    <>
      <div className="wrapper d-none d-lg-block">
        <nav id="sidebar">
          <ul className="list-unstyled">
            <li>
              <NavLink activeclassname="active" to="/admin-videos">Videos</NavLink>
            </li>
            <li>
              <NavLink activeclassname="active" to="/admin-ranking">Ranking</NavLink>
            </li>
            <li>
              <NavLink activeclassname="active" to="/admin-users">Users</NavLink>
            </li>

            <div className="accordion" id="accordionPanelsStayOpenExample">
              <div className="accordion-item">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  Competitions
                  <span className="arrow-icon"></span>
                </button>
                <div
                  id="panelsStayOpen-collapseOne"
                  className="accordion-collapse collapse overflow-y-auto"
                  data-bs-parent="#accordionPanel"
                  style={{maxHeight: 250}}
                >
                  <li>
                    <NavLink activeclassname="active" to="/new-competition">New competition</NavLink>
                  </li>
                  {data.ok && data.data.competitions.length !== 0 && (
                    data.data.competitions.sort((a, b) =>
                      new Date(b.start_date) - new Date(a.start_date))
                      .map((competition) => (
                        <li key={competition.id}>
                          <NavLink activeclassname="active" to={`/admin-winners/${competition.id}`}>{competition.name}</NavLink>
                        </li>
                      ))
                  )}
                </div>
              </div>
            </div>
          </ul>
        </nav>
      </div>
    </>
  )
}

export default AdminSideBar
