import { useEffect } from 'react'
import { useLocation, Outlet } from 'react-router'
import Header from '../containers/Header'
import Footer from '../containers/Footer'
import VideoModal from '../components/VideoModal'

const Layout = () => {
  const location = useLocation()

  useEffect(() => {
    if (!location.hash) return window.scrollTo(0, 0)
    const element = document.getElementById(location.hash.slice(1))
    element?.scrollIntoView({ behavior: 'smooth' })
  }, [location])

  return (
    <>
      <Header />
      <div className="content mt-102px">
        <Outlet />
      </div>
      <Footer />
      <VideoModal />
    </>
  )
}

export default Layout
