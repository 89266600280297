import {Card, Col, Stack} from 'react-bootstrap'
import * as constants from '../constants'
import playIcon from '../assets/landing/playIcon.png'
import PropTypes from 'prop-types'
import { useContext} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrashCan} from '@fortawesome/free-regular-svg-icons'
import { AppContext } from '../context'

const AdminRecommendedVideosCard = ({video, removeRecommended}) => {
  const { openVideoModal } = useContext(AppContext)

  return (
    <Col key={video.id} xl={3} md={4} sm={12} className="mb-4">
      <Card className='video-card crop h-100'>
        <div style={{position:'absolute',zIndex:100, bottom:0, backgroundColor:'rgba(52, 52, 52, 0.6)', width:'100%', padding:5}}>
          <div>
            <p style={{color:'white', fontSize:15, marginTop:5, marginLeft:5}}><b>{video.name}</b></p>
            <hr style={{color:'white'}} />
            <p onClick={()=>{removeRecommended(video.id, false)}} style={{textAlign:'center', color:'lightgrey', fontSize:16, marginTop:15, cursor:'pointer'}}>Remove from <span style={{fontWeight:'bold'}}>Recommended</span>
              <FontAwesomeIcon
                icon={faTrashCan}
                size="lg"
                className="me-2"
                style={{paddingLeft:10}}
              />
            </p>
          </div>
        </div>

        <Card.Img src={`${video.thumbnail.indexOf('http://') === 0 || video.thumbnail.indexOf('https://') === 0 ? '' : constants.URL}${video.thumbnail}`} alt="Card image" />
        <Card.ImgOverlay className="text-white h-100 d-flex flex-column justify-content-end">
          <div className='Card_Info'>
            { video.is_staff_pick &&
            <div className="Card_Info_Item">
              <p>Staff pick</p>
            </div>
            }
            { video.recommended &&
            <div className="Card_Info_Item">
              <p>Recommended</p>
            </div>
            }
          </div>
          <img src={playIcon} className="play-icon cursor-pointer" alt="" onClick={() => openVideoModal({...video, isAdmin: true})} />
          <div>
            <Stack direction="horizontal" gap={4} className="justify-content-center">
              <div>
              </div>
            </Stack>
          </div>
        </Card.ImgOverlay>
      </Card>
    </Col>
  )
}

AdminRecommendedVideosCard.propTypes = {
  video: PropTypes.object.isRequired,
  removeRecommended: PropTypes.func,
}

export default AdminRecommendedVideosCard