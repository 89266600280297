import { Image } from 'react-bootstrap'
import SocialIcon from './SocialIcon'
import { useContext } from 'react'
import PropTypes from 'prop-types'
import * as constants from '../constants'
import { AppContext } from '../context'
import { getVideosDetails } from '../api/videos'

const LeaderboardPosition = (props) => {
  const { token, unregisteredUserId, openVideoModal } = useContext(AppContext)

  const openModal = async () => {
    const response = await getVideosDetails(token, props.id, { unregistered_user_id: unregisteredUserId })

    if (response.error) console.error(response.error)
    else if (response) {
      openVideoModal({ ...response, refetch: props.refetch })
    }
  }

  return (
    <>
      <div
        className="LeaderboardPosition cursor-pointer"
        onClick={openModal}
      >
        <Image
          src={`${props.image.indexOf('http://') === 0 || props.image.indexOf('https://') === 0 ? '' : constants.URL}${props.image}`}
          rounded="true"
          className="imageLeaderboard"
        />
        <div
          className="d-flex align-items-center gap-4"
          style={{ padding: 20 }}
        >
          <span
            className="d-none d-lg-block"
            style={{ fontSize: 40, color: '#434968' }}
          >
            <SocialIcon socialMedia={props.socialNet} />
          </span>
          <div className="d-flex align-items-center flex-wrap gap-2 gap-md-4">
            <span className="LeaderboardText">
              #{props.position} {props.videoName}
            </span>
            <span className="FirstPosName text-2">
              {props.firstName} {props.lastName} ({props.country})
            </span>
          </div>
        </div>
        <hr className="mt-2" />
      </div>
    </>
  )
}

LeaderboardPosition.propTypes = {
  video: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
  socialNet: PropTypes.string.isRequired,
  likes: PropTypes.number.isRequired,
  videoName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  videoSrc: PropTypes.string.isRequired,
  staffPick: PropTypes.bool.isRequired,
  image: PropTypes.string.isRequired,
  voted: PropTypes.bool,
  country: PropTypes.string.isRequired,
  refetch: PropTypes.func,
}

export default LeaderboardPosition
