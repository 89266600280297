import {Button} from 'react-bootstrap'
import { Helmet } from 'react-helmet'

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>Not Found | Outback Explorer Competition</title>
      </Helmet>
      <div style={{textAlign:'center', marginTop:'20vh'}}>
        <h1 className='NotFound' style={{marginTop:50}}>404 ERROR</h1>
        <p>The page you were looking for cannot be found</p>
        <Button variant="" href="/" style={{ backgroundColor:'red', borderWidth:0, color:'white', fontWeight:'bold'}}>
          <span style={{aligntext:'center'}}>Return to home</span>
        </Button>
      </div>
    </>
  )
}

export default NotFound