import Logo from '../assets/landing/rfds-rs-logo.png'
import rsk1 from '../assets/landing/Rescue-Swag-Kit-1.webp'
import rsk2 from '../assets/landing/Rescue-Swag-Kit-2.webp'
import ADV from '../assets/landing/ADV.webp'
import EXP from '../assets/landing/EXP.webp'
import YoutubeEmbed from '../components/YoutubeEmbed'
import { Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

const WhoWeAre = () => {
  return (
    <>
      <Helmet>
        <title>Who We Are | Rescue Swag + Royal Flying Doctor Service QLD</title>
      </Helmet>
      <section className="WhoWeAre">
        <img
          src={Logo}
          alt="Rescue Swag and Royal Flying Doctor Service logo"
          className="WhoWeAre__Logo"
        />

        <div className="d-flex flex-column gap-2">
          <p className="fw-bold">
          Rescue Swag — Australia’s toughest first aid kit — will keep you and
          your family safe no matter where you work, travel, or explore.
          </p>
          <p>
          Owned by the Royal Flying Doctor Service (Queensland Section) (RFDS),
          the profits from Rescue Swag help the Flying Doctor ensure people in
          outback communities have access to the finest healthcare.
          </p>
          <p>
          Designed in rural Australia and created in response to a need for
          portable, comprehensive safety response, Rescue Swag has helped people
          prepare for the unexpected since its humble beginnings in 2012,
          through to its first appearance on Shark Tank Australia Series 1.
          </p>
          <p>
          In 2022, after supplying medical chests to outback Queenslanders for
          more than 80 years, the RFDS joined forces with Rescue Swag to deliver
          an innovative forward-thinking product that helps ensures people are
          prepared in an emergency no matter where they live or work.
          </p>
          <p>
          Proudly Australian owned, Rescue Swag first aid kits are available in
          a range of sizes and include the highest quality safety supplies that
          are essential for emergency situations — such as burns, snakebites,
          jellyfish stings, hiking falls and riding accidents — as well as your
          everyday first aid needs.
          </p>
        </div>

        <div className="WhoWeAre__Images_Container">
          <img
            src={ADV}
            alt={'Rescue Swag kit'}
            className="WhoWeAre__Images_Item"
          />
          <img
            src={EXP}
            alt={'Rescue Swag kit'}
            className="WhoWeAre__Images_Item"
          />
        </div>

        <div className="WhoWeAre__Buttons d-flex flex-column gap-4">
          <a
            href="https://www.rescueswag.com.au/"
            target="_blank"
            rel="noreferrer"
            className="w-100"
          >
            <Button variant={'rs w-100'}>Buy your Rescue Swag</Button>
          </a>

          <a
            href="https://www.flyingdoctor.org.au/qld/"
            target="_blank"
            rel="noreferrer"
            className="w-100"
          >
            <Button variant={'rs-white-yellow w-100'}>
              Learn more about the RFDS
            </Button>
          </a>
        </div>

        <YoutubeEmbed video="https://www.youtube.com/embed/1nXvQkiIgtM" className="HowToEnter__Video" />
      </section>
    </>
  )
}

export default WhoWeAre
