// eslint-disable-next-line no-undef
const URL = process.env.REACT_APP_URL_BACK_API
const API_URL = `${URL}/api`
// eslint-disable-next-line no-undef
const PAGE_URL = process.env.REACT_APP_URL_FRONT

export {
  URL,
  API_URL,
  PAGE_URL,
}
