import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Home from '../pages/Home'
import AllVideos from '../pages/AllVideos'
import Login from '../pages/auth/Login'
import Register from '../pages/auth/Register'
import AdminVideos from '../pages/admin/AdminVideos'
import AdminRanking from '../pages/admin/AdminRanking'
import AdminUsers from '../pages/admin/AdminUsers'
import AdminWinners from '../pages/admin/AdminWinners'
import NotFound from '../pages/NotFound'

import Layout from '../layout/Layout'
import AuthLayout from '../layout/AuthLayout'
import AdminLayout from '../layout/AdminLayout'
import ForgotPassword from '../pages/auth/ForgotPassword'
import LikedVideos from '../pages/user/LikedVideos'
import TermsAndConditions from '../pages/TermsAndConditions'
import RegisterToken from '../pages/auth/RegisterToken'
import WhoWeAre from '../pages/WhoWeAre'
import UnsubcribeEmail from '../pages/auth/UnsubcribeEmail'
import PastWinners from '../pages/PastWinners'
import ChoiceWinners from '../components/ChoiceWinners'
import AdminNewCompetition from '../pages/admin/AdminNewCompetition'
import AdminEditCompetition from '../pages/admin/AdminEditCompetition'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          
          <Route path="/all-videos/" element={<AllVideos />} />
          <Route path="/liked-videos" element={<LikedVideos />} />
          <Route path="/winners/:competition_id" element={<PastWinners />} />

          <Route path="/who-we-are" element={<WhoWeAre />} />
          <Route path="/terms&conditions" element={<TermsAndConditions />} />

          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path="/unsubscribe-email/:email" element={<UnsubcribeEmail />} />

        <Route element={<AuthLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register/check-mail/:token" element={<RegisterToken />} />
        </Route>

        <Route element={<AdminLayout />}>
          <Route path="/admin-videos" element={<AdminVideos />} />
          <Route path="/admin-ranking" element={<AdminRanking />} />
          <Route path="/admin-users" element={<AdminUsers />} />
          
          <Route path="/admin-winners/:competition_id" element={<AdminWinners />} />
          <Route path="/admin-winners/edit/:competition_id" element={<AdminEditCompetition />} />

          <Route path="/new-competition" element={<AdminNewCompetition />} />
          <Route path="/people-choice-winners/:competition_id" element={<ChoiceWinners />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App