import { getQuery, patchQuery, postQuery } from './apiFunctions'
import { getQueryNew, postQueryNew } from './apiQueries'

export const getVideos = async (params, token) => {
  params = Object.entries(params).reduce(
    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
    {}
  )
  return await getQuery({
    path: '/api/get-videos/',
    params,
    token,
    callback: (response) => response.data,
  })
}

export const getVideosDetails = async (token, id, params = {}) => {
  params = Object.entries(params).reduce(
    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
    {}
  )
  return await getQuery({
    path: `/api/get-video/${id}/`,
    params,
    token,
    callback: (response) => response.data,
  })
}

export const approveVideo = async (token, videoId, body) => {
  return await patchQuery({
    path: `/api/approve-video/${videoId}/`,
    token,
    body,
    callback: (response) => response.data,
  })
}



export const uploadVideo = async (body) => {
  return await postQuery({
    path: '/api/add-video/',
    body,
  })
}

export const getLeaderboard = async (params, token) => {
  params = Object.entries(params).reduce(
    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
    {}
  )
  return await getQuery({
    path: '/api/get-rating-videos/',
    params,
    token,
    callback: (response) => response.data,
  })
}

export const getLeaderboardByAdmin = async (params) => {
  params = Object.entries(params).reduce(
    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
    {}
  )
  return await getQueryNew({
    path: '/get-rating-videos/',
    params,
  })
}

export const patchEditVideo = async (token, videoId, body) => {
  return await patchQuery({
    path: `/api/admin-edit-video/${videoId}/`,
    token,
    body,
    callback: (response) => response.data,
  })
}


export const voteVideo = async (videoId, token, body) => {
  return await postQueryNew({
    path: `/vote-video/${videoId}/`,
    body: JSON.stringify(body),
    token
  })
}

export const unvoteVideo = async (videoId, token, body) => {
  return await postQueryNew({
    path: `/unvote-video/${videoId}/`,
    body: JSON.stringify(body),
    token
  })
}

export const post_luckyWinner = async (body, token) => {
  return await postQueryNew({
    path: '/lucky-winner/',
    body: JSON.stringify(body),
    token
  })
}

export const post_selectedWinner = async (body, token) => {
  return await postQueryNew({
    path: '/selected-winner/',
    body: JSON.stringify(body),
    token
  })
}

export const get_luckyWinner = async (params) => {
  return await getQueryNew({
    path: '/lucky-winner/',
    params,
  })
}

export const get_selectedWinner = async (params) => {
  return await getQueryNew({
    path: '/selected-winner/',
    params,
  })
}

export const getVideosDetailsNew = async (token, id, params = {}) => {
  params = Object.entries(params).reduce(
    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
    {}
  )
  return await getQueryNew({
    path: `/get-video/${id}/`,
    params,
    token,
  })
}
