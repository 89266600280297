import { Helmet } from 'react-helmet'
import { Card } from 'react-bootstrap'
import ADV from '../assets/landing/ADV.webp'
import EXP from '../assets/landing/EXP.webp'
import { Button } from 'react-bootstrap'
import { useContext, useMemo } from 'react'
import { AppContext } from '../context'
import { useQuery } from 'react-query'
import { getLeaderboardByAdmin, get_luckyWinner, get_selectedWinner } from '../api/videos'
import playIcon from '../assets/landing/playIcon.png'
import { getCompetitions } from '../api/competitions'
import { Link, useParams } from 'react-router-dom'
import classNames from 'classnames'
import * as constants from '../constants'

const PastWinners = () => {
  const { openVideoModal } = useContext(AppContext)
  const { competition_id } = useParams()

  const { data: competitionsData } = useQuery(
    ['getCompetitions'],
    () => getCompetitions(),
    {
      initialData: {
        data: {
          competitions: [],
          current_page: 1,
          total_pages: 1,
        },
        ok: false,
      },
      refetchOnWindowFocus: false
    }
  )

  const getLastUpcomingCompetition = (competitionsData) => {
    const currentDate = new Date()

    const pastCompetitions = competitionsData.data.competitions.filter(
      (competition) => {
        const competitionEndDate  = new Date(competition.end_date)
        return competitionEndDate  < currentDate
      }
    )

    if (pastCompetitions.length > 0) {
      pastCompetitions.sort((a, b) =>
        new Date(b.end_date) - new Date(a.end_date)
      )

      return pastCompetitions 
    }

    return undefined
  }

  const lastFinishedCompetition  = getLastUpcomingCompetition(competitionsData)

  const { data: leaderboardData } = useQuery(
    ['getLeaderboardByAdmin',competition_id],
    () => getLeaderboardByAdmin({ per_page: '3', competition_id }),
    {
      initialData: { leaderboardData: [] },
    }
  )

  const { data: selectedWinnerData } = useQuery(
    ['getSelectedWinner', competition_id],
    () => get_selectedWinner({ competition_id }),
    {
      initialData: {
        data: {
          admin_description: '',
          description: '',
          hosted_in: '',
          id: null,
          is_banned_from_leaderboard: false,
          is_staff_pick: false,
          last_edited_by: 0,
          name: '',
          reason_rejected: '',
          recommended: false,
          status: '',
          thumbnail: '',
          upload_time: '',
          user_age: '',
          user_city: '',
          user_country: '',
          user_email: '',
          user_last_name: '',
          user_name: '',
          user_phone: '',
          video_link: '',
        },
        ok: false,
      },
    }
  )

  const { data: luckyWinnerData } = useQuery(
    ['getLuckyWinner', competition_id],
    () => get_luckyWinner({ competition_id }),
    {
      initialData: {
        data: {
          description: '',
          hosted_in: '',
          id: null,
          is_banned_from_leaderboard: false,
          is_staff_pick: false,
          last_edited_by: 0,
          name: '',
          reason_rejected: '',
          recommended: false,
          status: '',
          thumbnail: '',
          upload_time: '',
          user_age: '',
          user_city: '',
          user_country: '',
          user_email: '',
          user_last_name: '',
          user_name: '',
          user_phone: '',
          video_link: '',
        },
        ok: false,
      },
    }
  )

  const competitionSelected = useMemo(() => {
    if (!competitionsData.ok) return undefined
    return competitionsData.data.competitions.find((competition) =>
      competition.id === Number(competition_id)
    )

  }, [competitionsData, competition_id])

  return (
    <>
      <Helmet>
        <title>Past Winners | Rescue Swag + Royal Flying Doctor Service QLD</title>
      </Helmet>
      <section className='Winners'>
        <ul style={{ listStyleType: 'none', display: 'flex', gap: '20px' }}>
          {lastFinishedCompetition && lastFinishedCompetition.length !== 0 && (
            lastFinishedCompetition.sort((b, a) =>
              new Date(a.start_date) - new Date(b.start_date))
              .map((competition) => (
                <li key={competition.id} className={classNames('LinkForYearCompetition', { Active: competition.id === Number(competition_id) })}>
                  <Link activeclassname="active" to={`/winners/${competition.id}`}>{competition.name}</Link>
                </li>
              ))
          )}
        </ul>
        <hr style={{ margin: '0px' }} />
      </section>
      <section className='Winners'>
        {competitionSelected ? (
          <>
            <div className='d-flex justify-content-center'>
              <h1 className="hero-text font_roboto_slab mb-5">
                {competitionSelected.subtitle}
              </h1>
            </div>

            {competitionSelected.is_active_selected_winner && (
              <>
                <div className='mt-5 mb-4'>
                  <h4 className="font_roboto_slab">
                    {competitionSelected.selected_winner_title} :
                  </h4>
                </div>
                <div>
                  <p>{selectedWinnerData.data.user_name} {selectedWinnerData.data.user_last_name} </p>
                  {selectedWinnerData.data.thumbnail &&
                    <div className="AdminVideoRanking__Video_Container">
                      <Card className="video-card crop w-100 h-100">
                        <Card.Img src={`${selectedWinnerData.data.thumbnail.indexOf('http://') === 0 || selectedWinnerData.data.thumbnail.indexOf('https://') === 0 ? '' : constants.URL}${selectedWinnerData.data.thumbnail}`} alt="Card image" className="h-100" />
                        <Card.ImgOverlay className="text-white h-100 d-flex flex-column justify-content-end">
                          <img src={playIcon} className="play-icon cursor-pointer" alt="" onClick={() => openVideoModal({ ...selectedWinnerData.data, isAdmin: false })} />
                        </Card.ImgOverlay>
                      </Card>
                    </div>
                  }
                  <div className="my-4 admin-description" dangerouslySetInnerHTML={{ __html: selectedWinnerData.data.admin_description }} />
                </div>
                <hr />
              </>
            )}
            {competitionSelected.is_active_leaderboard_winners && (
              <>
                <div className='d-flex flex-column gap-2 mt-2'>
                  <h4 className="font_roboto_slab">
                    {competitionSelected.leaderboard_winners_title} :
                  </h4>
                  {leaderboardData.ok && (
                    <ul className="custom-list flex mt-3">
                      {leaderboardData.data.map((winner, index) => (
                        <li key={index}>
                          <div className='d-flex gap-2'>
                            <p>- {winner.user_name} {winner.user_last_name}</p>
                            <p
                              className="video-link-princ .text-xs"
                              onClick={() => openVideoModal({ ...winner, isAdmin: false })}
                            >
                             ( View video )
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div >
              </>
            )}
            {competitionSelected.is_active_lucky_winner_winners && (
              <>
                <div className='d-flex flex-column gap-2 mt-4'>
                  <h4 className="font_roboto_slab">
                    {competitionSelected.lucky_winner_title} :
                  </h4>
                  {luckyWinnerData.ok && (
                    <div className='d-flex gap-2 mt-3'>
                      <p>{luckyWinnerData.data.winner_name}</p>
                    </div>
                  )}
                </div>
              </>
            )}
            <div className='d-flex justify-content-center'>
              <p>Congratulations to all our winners!</p>
            </div>
            <hr />
            <div className="Winners__Images_Container my-4">
              <img
                src={ADV}
                alt={'Rescue Swag kit'}
                className="Winners__Images_Item"
              />
              <img
                src={EXP}
                alt={'Rescue Swag kit'}
                className="Winners__Images_Item"
              />
            </div>
            <div className='d-flex flex-column gap-2 mt-3'>
              <h4 className="d-flex justify-content-center font_roboto_slab mb-4">
                What is Rescue Swag?
              </h4>
              <p>
                Rescue Swag first aid kits are an essential tool in emergency situations.
              </p>
              <p>
                They contain vital medical supplies that can be used to administer care until more advanced medical assistance arrives.
                When it comes to choosing a kit, durability and reliability are crucial factors.
              </p>
              <p>
                Our kits are built for an active, outdoor lifestyle and made from durable materials to keep them water resistant, dustproof and accessible in times of need.
              </p>
              <p>
                Rescue Swag is owned by the Royal Flying Doctor Service (Queensland Section) which means that every purchase made helps support the RFDS to deliver vital healthcare services
                to communities across the state.
                Our kits are trusted by professionals in various industries making us a reliable choice for anyone in need of a high-quality first aid kit.
              </p>
            </div>

            <div className="Winners__Buttons d-flex flex-column gap-4 mt-5">
              <a
                href="https://www.rescueswag.com.au/"
                target="_blank"
                rel="noreferrer"
                className="w-100"
              >
                <Button variant={'rs w-100'}>Buy your Rescue Swag</Button>
              </a>

              <a
                href="https://www.flyingdoctor.org.au/qld/"
                target="_blank"
                rel="noreferrer"
                className="w-100"
              >
                <Button variant={'rs-white-yellow w-100'} className='mb-5'>
                  Learn more about the RFDS
                </Button>
              </a>
            </div>
          </>
        ) : (
          <div className='mt-4'>
            <h3>This competition doesn&apos;t exist</h3>
          </div>
        )}
      </section >
    </>
  )
}

export default PastWinners