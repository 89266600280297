import axios from 'axios'
import { URL } from '../constants'

export const getQuery = async ({
  path,
  token = undefined,
  params = undefined,
  callback = (data) => data,
}) => {
  const headers = token && {
    Authorization: `Token ${token}`,
  }

  try {
    const { data, status } = await axios.get(`${URL}${path}`, {
      headers,
      params,
    })

    return callback({ data, status })
  } catch (error) {
    return handleError(error)
  }
}

export const postQuery = async ({
  path,
  token = undefined,
  body = {},
  callback = (data) => data,
}) => {

  const headers = token && {
    Authorization: `Token ${token}`,
  }

  try {
    const { data, status } = await axios.post(`${URL}${path}`, body, {
      headers,
    })

    return callback({ data, status })
  } catch (error) {
    return handleError(error)
  }
}

export const deleteQuery = async ({
  path,
  token = undefined,
  body = {},
  callback = (data) => data,
}) => {

  const headers = token && {
    Authorization: `Token ${token}`,
  }

  try {
    const { data, status } = await axios.delete(`${URL}${path}`, {
      headers,
      data: body,
    })

    return callback({ data, status })
  } catch (error) {
    return handleError(error)
  }
}

export const putQuery = async ({
  path,
  token = undefined,
  body = {},
  callback = (data) => data,
}) => {

  const headers = token && {
    Authorization: `Token ${token}`,
  }

  try {
    const { data, status } = await axios.put(`${URL}${path}`, body, {
      headers,
    })

    return callback({ data, status })
  } catch (error) {
    return handleError(error)
  }
}

export const patchQuery = async ({
  path,
  token = undefined,
  body = {},
  callback = (data) => data,
}) => {

  const headers = token && {
    Authorization: `Token ${token}`,
    'Content-Type': 'multipart/form-data',
  }

  try {
    const { data, status } = await axios.patch(`${URL}${path}`, body, {
      headers,
    })

    return callback({ data, status })
  } catch (error) {
    return handleError(error)
  }
}

const handleError = (error) => {
  console.error(error.response.data)
  if (
    typeof error.response.data === 'string' &&
    error.response.data.startsWith('<!DOCTYPE html>')
  )
    return { data: {error: 'internal server error'}, status: error.response.status }
  if (error.response.data.detail == 'Invalid token.') {
    localStorage.removeItem('token')
    window.dispatchEvent(new Event('storage'))
  }
    
  return { data: error.response.data, status: error.response.status }
}