import { Button, Container } from 'react-bootstrap'
import YoutubeEmbed from './YoutubeEmbed'
import { DownloadModalContext } from '../pages/Home'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../context'
import infoBackground from '../assets/landing/AddVideoBackground.png'
import PropTypes from 'prop-types'

const HowToEnter = ({ openVideo }) => {
  const { setShow } = useContext(DownloadModalContext)
  const { token, timelineCompetitions } = useContext(AppContext)

  const currentVideoURL = timelineCompetitions.currentCompetition?.explicative_video
  const lastCompetitionVideoURL = timelineCompetitions.lastCompetitions[0]?.explicative_video
  const nextCompetitionVideoURL = timelineCompetitions.nextCompetition?.explicative_video

  const getIDFromURL = (url) => {
    return url ? url.split('/').pop() : null
  }

  const currentID = getIDFromURL(currentVideoURL)
  const lastCompetitionID = getIDFromURL(lastCompetitionVideoURL)
  const nextCompetitionID = getIDFromURL(nextCompetitionVideoURL)

  return (
    <section className="mb-5" id="how-to-enter">
      <Container>
        {timelineCompetitions.currentCompetition && !timelineCompetitions.nextCompetition && (
          <YoutubeEmbed video={`https://www.youtube.com/embed/${currentID}`} className="HowToEnter__Video" />
        )}
        {timelineCompetitions.lastCompetitions && timelineCompetitions.lastCompetitions.length !== 0 && !timelineCompetitions.nextCompetition && !timelineCompetitions.currentCompetition && (
          <YoutubeEmbed video={`https://www.youtube.com/embed/${lastCompetitionID}`} className="HowToEnter__Video" />
        )}
        {timelineCompetitions.nextCompetition && (
          <YoutubeEmbed video={`https://www.youtube.com/embed/${nextCompetitionID}`} className="HowToEnter__Video" />
        )}
        <h1 className="LandingTitle text-center mt-5 mb-0">How to enter</h1>
        <Button variant="rs-white d-block mb-5 mx-auto" onClick={openVideo}>
          <u>Watch how to enter video</u>
        </Button>
        <div className="HowToEnter__Info">
          <div className="d-flex gap-4">
            <p className="HowToEnter__Number font_roboto_slab">1</p>
            <div className="d-flex flex-column gap-1">
              <h3 className="font_roboto_slab mb-0">Download</h3>
              <p className="mb-0">
                Download the Outback Explorer Competition video frames to get
                started! These frames must be included in your submission, both
                at the beginning and end of your video.
              </p>
              <Button
                id="Step1-Download-Btn-Btn"
                variant={'rs'}
                className="HowToEnter__Button px-0 px-md-5 mt-2"
                onClick={() => {
                  setShow(true)
                }}
              >
                Download video frames
              </Button>
            </div>
          </div>
          <div className="d-flex gap-4">
            <p className="HowToEnter__Number font_roboto_slab">2</p>
            <div className="d-flex flex-column gap-1">
              <h3 className="font_roboto_slab mb-0">Create</h3>
              <p className="mb-0">
                Create your video and upload it to your chosen social media
                platform using the hashtag <strong>#rescueswag</strong>.
              </p>
              <p className="mb-0">
                Only videos posted on Instagram, YouTube or TikTok will be
                eligible for entry.
              </p>

              <div className="position-relative mt-3">
                <img
                  src={infoBackground}
                  alt="info background"
                  className="HowToEnter__InfoBackground"
                />
                <p className="fw-bold">
                  If posting from Instagram, please ensure:
                </p>
                <p className="mb-0 ms-3">
                  {'1) Your Instagram account is set to public.'}
                </p>
                <p className="mb-0 ms-3">
                  {'2) Your video includes original audio only.'}
                </p>
                <p className="ms-3">
                  {'3) Your video is in a portrait aspect ratio.'}
                </p>
                <p className="mb-0">
                  This will help ensure that your video is viewable on the
                  Outback Explorer Competition website.
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex gap-4">
            <p className="HowToEnter__Number font_roboto_slab">3</p>
            <div className="d-flex flex-column gap-1">
              <h3 className="font_roboto_slab mb-0">Upload</h3>
              <p className="mb-0">
                Upload your video on the Outback Explorer Competition website by
                copying the link for your content and pasting it into the form
                below.
              </p>
              <Button
                id="Step3-Enter-Btn"
                href="#enter-competition"
                className="HowToEnter__Button px-5 mt-2"
                variant={'rs'}
              >
                Enter competition
              </Button>
            </div>
          </div>
          <div className="d-flex gap-4">
            <p className="HowToEnter__Number font_roboto_slab">4</p>
            <div className="d-flex flex-column gap-1">
              <h3 className="font_roboto_slab mb-0">Share & vote</h3>
              <p className="mb-0">
                Share your video and cast one vote per day for your favourite
                competition entries. Want more voting power? Sign up for an
                account to cast <strong>five votes per day</strong> plus be
                entered into the draw to <strong>win a Rescue Swag!</strong>
              </p>
              {!token ? (
                <Link to="/register">
                  <Button
                    id="Step4-Create-Account-Btn"
                    variant={'rs'}
                    className="HowToEnter__Button px-5 mt-2"
                  >
                    Create voting account
                  </Button>
                </Link>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

HowToEnter.propTypes = {
  openVideo: PropTypes.func.isRequired,
}

export default HowToEnter
