import { Col, Row, Container, InputGroup, FormControl } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
// import AuthImg from '../../assets/auth/authimg.png'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { login, resendEmailConfirmation } from '../../api/users'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { AppContext } from '../../context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { Helmet } from 'react-helmet'

const Login = () => {
  const { setToken, userLog, setUserLog } = useContext(AppContext)
  const [generalError, setGeneralError] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showReSend, setShowReSend] = useState(false)
  const [disableReSendMail, setDisableReSendMail] = useState(false)
  const navigate = useNavigate()

  const handlerShowPassword = () => {
    setShowPassword((prev) => !prev)
  }

  const formik = useFormik({
    initialValues: {
      email: userLog.email,
      password: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      email: Yup.string()
        .required('E-mail is required.')
        .max(60, 'Maximum characters: 60.'),
      password: Yup.string()
        .required('Password is required.')
        .min(8, 'Minimum characters: 8.')
        .max(40, 'Maximum characters: 40.'),
    }),
    onSubmit: async ({ email, password }) => {
      if (email.includes(' ')) {
        email = email.replace(/ /g, '')
      }

      setUserLog((prev) => ({
        ...prev,
        email,
      }))

      const response = await login({ email, password })
      if (response.key) {
        setToken(response.key)
        navigate('/')
        setGeneralError('')
      }
      if (response.data.non_field_errors) {
        if (
          response.data.non_field_errors[0] ===
          'Unable to log in with provided credentials.'
        )
          setGeneralError(
            'Incorrect email or password.'
          )
        else if (response.data.non_field_errors[0] === 'E-mail is not verified.') {
          setGeneralError('E-mail is not verified.')
          setShowReSend(true)
        } else console.error(response.data.non_field_errors)
      } 
    },
  })

  const reSendMail = async () => {
    setDisableReSendMail(true)
    const response = await resendEmailConfirmation({
      email: formik.values.email,
    })

    if (response.error) console.error(response.error)
    else if (response) {
      console.log('mail forwarded')
    }
    setDisableReSendMail(false)
  }

  useEffect(() => {
    setShowReSend(false)
  }, [formik.values.email])

  return (
    <>
      <Helmet>
        <title>Log in For More Votes | Outback Explorer Competition</title>
      </Helmet>
      <div className="AuthContainer">
        <Container fluid>
          <Row>
            <Col
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
              xxl={{ span: 4, offset: 4 }}
              className="mt-5"
            >
              <div className="bg-white AuthBox mb-4">
                <Container fluid>
                  <Row>
                    <Col className="p-4">
                      <div className="text-center">
                        <h1 className="mb-4">Log in</h1>
                        <p>
                        Log in to your account to use your extra daily votes
                        </p>
                      </div>
                      <Form className="mb-4" onSubmit={formik.handleSubmit}>
                        <Form.Group
                          className="position-relative mb-4 fw-bold"
                          controlId="email"
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            isInvalid={!!formik.errors.email}
                            autoComplete="email"
                          />
                          <Form.Control.Feedback
                            className="position-absolute mt-0 text-5"
                            type="invalid"
                          >
                            {formik.errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-4 fw-bold" controlId="password">
                          <Form.Label>Password</Form.Label>
                          <InputGroup className="mt-3">
                            <FormControl
                              type={showPassword ? 'text' : 'password'}
                              value={formik.values.password}
                              onChange={formik.handleChange}
                              isInvalid={!!formik.errors.password}
                              autoComplete="current-password"
                            />
                            <Button
                              variant="outline-secondary"
                              onClick={handlerShowPassword}
                            >
                              {showPassword ? (
                                <FontAwesomeIcon icon={faEyeSlash} />
                              ) : (
                                <FontAwesomeIcon icon={faEye} />
                              )}
                            </Button>
                            <Form.Control.Feedback
                              className="mt-0 text-5"
                              type="invalid"
                            >
                              {formik.errors.password}
                            </Form.Control.Feedback>
                          </InputGroup>
                          <Form.Text>
                          Forgot your password?{' '}
                            <Link to="/forgot-password">Reset here</Link>
                          </Form.Text>
                        </Form.Group>

                        {generalError.length !== 0 && (
                          <p className="text-red">{generalError}</p>
                        )}
                        {showReSend ? (
                          <button
                            className="mb-3 text-decoration-underline"
                            disabled={disableReSendMail}
                            onClick={reSendMail}
                          >
                            {disableReSendMail
                              ? 'sending...'
                              : 'I did not receive verification email. Re-send'}
                          </button>
                        ) : (
                          <></>
                        )}

                        <div className="d-grid gap-2 mt-auto pt-2">
                          <Button variant="rs" type="submit">
                          Log in
                          </Button>
                        </div>
                      </Form>
                      <div className="AlreadyAccount">
                        <Link to="/register" className="d-grid gap-2 mt-auto">
                          <Button variant="rs-white-yellow">Sign up</Button>
                        </Link>
                      </div>
                    </Col>
                    {/* <Col sm={12} md={6} className="p-0">
                    <div className="RightAuth">
                      <img src={AuthImg} className="img-fluid" alt="" />
                      <div className="AuthBoxPrice">
                        <h3>Want to win a prize?</h3>
                        <p className="border-top border-color-yellow pt-4 text-center">
                          Creating an account not only will{' '}
                          <span className="fw-bold">triple your votes</span> but
                          also all account creators will also be entered into a
                          draw to{' '}
                          <span className="fw-bold">
                            win an Adventurer Rescue Swag first aid kit.
                          </span>
                        </p>
                      </div>
                    </div>
                  </Col> */}
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Login