import {Card, Col, Row} from 'react-bootstrap'
import playIcon from '../assets/landing/playIcon.png'
import PropTypes from 'prop-types'
import * as constants from '../constants'
import { useContext } from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEllipsisVertical} from '@fortawesome/free-solid-svg-icons'
import Dropdown from 'react-bootstrap/Dropdown'
import { AppContext } from '../context'

const AdminVideoRanking = (props) => {
  const { openVideoModal } = useContext(AppContext)

  return (
    <div
      className="AdminVideoRanking mb-4"
    >
      <div className="d-flex justify-content-center align-items-center h-100" style={{backgroundColor: '#F9F9F9'}}>
        <h3>{props.position}</h3>
      </div>
      <div className="AdminVideoRanking__Video_Container">
        <Card className="video-card crop w-100 h-100">
          <Card.Img src={`${props.image.indexOf('http://') === 0 || props.image.indexOf('https://') === 0 ? '' : constants.URL}${props.image}`} alt="Card image" className="h-100" />
          <Card.ImgOverlay className="text-white h-100 d-flex flex-column justify-content-end">
            <img src={playIcon} className="play-icon cursor-pointer" alt="" onClick={() => openVideoModal({...props.video, isAdmin: true})} />
          </Card.ImgOverlay>
        </Card>
      </div>
      <div className="AdminVideoRanking__Description p-4 border overflow-y-auto">
        <h4>{props.videoName}</h4>
        <p>{props.description}</p>
      </div>
      <div style={{backgroundColor: '#F9F9F9'}} className="h-100 border">
        <div className="float-end text-end cursor-pointer mt-1" style={{position:'relative', right:20, top:10}}>
          <Dropdown>
            <Dropdown.Toggle variant="secondary" className="menu-dropdown">
              <FontAwesomeIcon icon={faEllipsisVertical} color="black" size="xl" style={{height:20}} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={()=>{props.deleteFromRanking(props.id)}}>Remove from ranking</Dropdown.Item>
              { props.staffPick ?
                <Dropdown.Item onClick={()=>{props.staffPickVideo(props.id, false)}}>Remove staff pick</Dropdown.Item>
                :
                <Dropdown.Item onClick={()=>{props.staffPickVideo(props.id, true)}}>Mark as staff pick</Dropdown.Item>
              }
              <Dropdown.Item onClick={()=>{props.rejectVideoModal(props.id)}}>Reject video</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="admin-video-description-row bg-light p-3">
          <h5 className="mb-3">{props.firstName} {props.lastName}</h5>
          <p className="admin-label">Email</p>
          <p className="admin-field">{props.email}</p>
          <Row>
            <Col>
              <p className="admin-label">Age</p>
              <p className="admin-field">{props.age}</p>
            </Col>
            <Col>
              <p className="admin-label">Mobile</p>
              <p className="admin-field">{props.mobile}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="admin-label">Country</p>
              <p className="admin-field">{props.country}</p>
            </Col>
            <Col>
              <p className="admin-label">City</p>
              <p className="admin-field">{props.city}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="admin-label">Likes</p>
              <p className="admin-field">{props.likes}</p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default AdminVideoRanking

AdminVideoRanking.propTypes = {
  video: PropTypes.object.isRequired,
  id: PropTypes.number,
  position: PropTypes.number.isRequired,
  socialNet: PropTypes.string.isRequired,
  likes: PropTypes.number.isRequired,
  videoName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  videoSrc: PropTypes.string.isRequired,
  staffPick: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  mobile: PropTypes.string.isRequired,
  age: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  staffPickVideo: PropTypes.func,
  rejectVideoModal: PropTypes.func,
  deleteFromRanking: PropTypes.func,
}
