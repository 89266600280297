import FormCompetition from './FormCompetition'
import infoBackground from '../assets/landing/AddVideoBackground.png'
import outbackLogo from '../assets/landing/outback-logo-blue.svg'
import socialMediaLogos from '../assets/landing/social-media-logos.svg'
import clockWatch from '../assets/landing/clock-watch.svg'
import {DownloadModalContext} from '../pages/Home'
import { useContext } from 'react'

const SubmitVideo = () => {
  const {setShow} = useContext(DownloadModalContext)
  return (
    <section className="mb-5 mt-5" id="enter-competition">
      <div className='SubmitVideo_Container container'>
        <h2 className="LandingTitle text-center mb-5">Enter competition</h2>
        <div className='SubmitVideo'>
          <div className="SubmitVideo_Form mb-4">
            <p className='d-block SubmitVideo_Form_p d-xl-none text-1 fw-semibold'>Enter details</p>
            <FormCompetition />
          </div>
          <div className="SubmitVideo_Info">
            
            <div className='d-flex justify-content-center align-items-center position-relative h-100'>
              <div className='SubmitVideo_Info_Content'>
                <img src={infoBackground} alt="info background" className='infoBackground' />
                <div className="ImportantCard">
                  <h4 className="SubmitVideo_Info_Text">Video requirements:</h4>
                  <div className='ImportantCard_Icon'>
                    <img
                      src={outbackLogo}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className='ImportantCard_info'>
                    <h5 className='text-4'>
                    Must include Outback Explorer Competition video frames both at the beginning and end of your video.
                    </h5>
                    <span className="text-decoration-underline cursor-pointer" onClick= {()=>{setShow(true)}}>Get them here!</span>
                  </div>
                  <div className='ImportantCard_Icon'>
                    <img
                      src={socialMediaLogos}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className='ImportantCard_info' style={{ width: '90%'}}>
                    <h5 className='text-4'>Must be uploaded to either Instagram, YouTube or TikTok using the hashtag #rescueswag</h5>
                  </div>
                  <div className="ImportantCard_Icon">
                    <img
                      src={clockWatch}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className='ImportantCard_info'>
                    <h5 className='text-4'>Must be no longer than 2 minutes long.</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </section>
  )
}

SubmitVideo.propTypes = {}

export default SubmitVideo