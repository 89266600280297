import { faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'


const SocialIcon = (props) => {
  const socialMedia  = props.socialMedia
  return (    
    <div style={{display : 'inline-block'}} >
      {socialMedia == 'Youtube'
        ? <FontAwesomeIcon icon={faYoutube} className="me-3" />
        : (socialMedia == 'TikTok'
          ? <FontAwesomeIcon icon={faTiktok} className="me-3" />
          : (socialMedia == 'Instagram'
            ? <FontAwesomeIcon icon={faInstagram} className="me-3" />
            : null)
        )
      }
    </div>
  )
}

export default SocialIcon

SocialIcon.propTypes = {
  socialMedia: PropTypes.string.isRequired,
}