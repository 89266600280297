import Container from 'react-bootstrap/Container'
import { Col, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import CompetitionLogo from '../assets/landing/competitionLogo.png'
import { AppContext } from '../context'
import { useContext } from 'react'
import facebookIcon from '../assets/icons/facebook-yellow.svg'
import instagramIcon from '../assets/icons/instagram-yellow.svg'
import youtubeIcon from '../assets/icons/youtube-yellow.svg'
import tiktokIcon from '../assets/icons/tiktok-yellow.svg'

const Footer = () => {
  const { token, competitionInfo, timelineCompetitions } = useContext(AppContext)
  return (
    <footer className="Footer">
      <Container>
        <Row className="py-5">
          <Col
            sm={12}
            md={4}
            className="text-center mb-4 gap-4"
            style={{
              alignContent: 'center',
              display: 'grid',
              justifyContent: 'center',
            }}
          >
            {competitionInfo && competitionInfo.state !== 'finished' ? (
              <>
                <Button id="Footer-Enter-Btn" href="/#enter-competition" variant="rs">
                  Enter Competition
                </Button>
                {!token && (
                  <Link to="/register" className="w-100">
                    <Button id="Footer-Create-Account-Btn" variant="rs-secondary" className="w-100">
                      Get more votes!
                    </Button>
                  </Link>
                )}
              </>
            ) : (
              <></>
            )}
            <div className="d-flex justify-content-between align-items-center gap-2 w-100">
              <a
                href="https://www.facebook.com/RescueSwag/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebookIcon} alt="Facebook icon" />
              </a>
              <a
                href="https://www.instagram.com/rescueswag/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagramIcon} alt="Facebook icon" />
              </a>
              <a
                href="https://www.youtube.com/@rescue-swag/about"
                target="_blank"
                rel="noreferrer"
              >
                <img src={youtubeIcon} alt="Facebook icon" />
              </a>
              <a
                href="https://www.tiktok.com/@rescueswag"
                target="_blank"
                rel="noreferrer"
              >
                <img src={tiktokIcon} alt="Facebook icon" />
              </a>
            </div>
          </Col>
          <Col sm={12} md={4} className="d-flex align-items-center justify-content-center">
            <img
              src={CompetitionLogo}
              className="mb-5"
              alt="Competition logo"
              width={127}
            />
          </Col>
          <Col sm={12} md={4} className="d-flex text-center align-items-center justify-content-center">
            <ul className="nav d-flex flex-column h-100 align-items-center gap-3">
              {competitionInfo && competitionInfo.state !== 'nearFuture' ? (
                <li className="nav-item">
                  <Link to="/all-videos/">Watch videos</Link>
                </li>
              ) : (
                <></>
              )}
              {timelineCompetitions.lastCompetitions && timelineCompetitions.lastCompetitions.length !== 0 && (
                <li className="nav-item">
                  <Link to={`/winners/${timelineCompetitions.lastCompetitions[0].id}`}>Past winners</Link>
                </li>
              )}
              <li className="nav-item">
                <a
                  href="https://www.rescueswag.com.au/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Buy your Rescue Swag
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://www.flyingdoctor.org.au/qld/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Royal Flying Doctor Service (QLD)
                </a>
              </li>
              <li className="nav-item">
                <Link to="/terms&conditions">Terms & conditions</Link>
              </li>
              <li className="nav-item">
                <a
                  href="https://careers.rfdsqld.com.au/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy policy
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Row className="w-100 me-0 ms-0">
        <Col className="text-center py-2 border-top border-secondary BottomNav">
          © Rescue Swag 2023
        </Col>
      </Row>
      <Row className="w-100 me-0 ms-0">
        <Col className="text-6 text-center py-2 Linkchar__Text">
          Powered by{' '}
          <a
            href="https://linkchar.com/"
            target="_blank"
            rel="noreferrer"
          >
            Linkchar
          </a>
        </Col>
      </Row>
    </footer>
  )
}

export default Footer
