import { useState, useRef, useEffect, useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { AppContext } from '../context'

const NavDropdown = () => {
  const {
    timelineCompetitions,
  } = useContext(AppContext)
  const navigate = useNavigate()

  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  const { currentCompetition, lastCompetitions, nextCompetition } = timelineCompetitions

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const closeDropdownOnOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', closeDropdownOnOutsideClick)
    return () => {
      document.removeEventListener('click', closeDropdownOnOutsideClick)
    }
  }, [])

  const handleCompetitionClick = (competition_id) => {
    const newParams = {}
    if (competition_id)
      newParams.competition_id = competition_id
    navigate({
      pathname: '/all-videos/',
      search: new URLSearchParams(newParams).toString()
    })
    setIsOpen(false)
  }

  return (
    <>
      <div ref={dropdownRef} className={`Nav-Drop ${isOpen ? 'show' : ''}`}>
        {currentCompetition ? (
          <NavLink to={`/all-videos/?competition_id=${currentCompetition.id}`} className="nav-link">
            Watch & Vote
          </NavLink>
        ) : (
          <>
            <button className="nav-link Nav-buttonDrop" onClick={toggleDropdown} aria-expanded={isOpen}>
              Watch Videos
              <FontAwesomeIcon
                icon={faCaretDown}
              />
            </button>
            <ul className={`Nav-Menu ${isOpen ? 'show' : ''}`}>
              <li className='CompetitionLink pb-2' onClick={() => handleCompetitionClick()}>All competitions</li>
              {timelineCompetitions.lastCompetitions && timelineCompetitions.lastCompetitions.length > 0 && (
                timelineCompetitions.lastCompetitions.map((lastCompetition) => (
                  <li key={lastCompetition.id} className='CompetitionLink pb-2' onClick={() => handleCompetitionClick(lastCompetition.id)}>
                    {lastCompetition.name}
                  </li>
                ))
              )}
            </ul>
          </>
        )}
      </div>
    </>
  )
}

export default NavDropdown