import { useContext, useEffect } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { likedVideos } from '../../api/users'
import VideoCard from '../../components/VideoCard'
import { AppContext } from '../../context'

const LikedVideos = () => {
  const { firstCharge, token } = useContext(AppContext)

  const { isFetching, data, refetch } = useQuery(
    ['liked-videos', token],
    () => likedVideos(token),
    {
      initialData: {
        videos_serializer: []
      },
      enabled: token !== '',
    }
  )
  const navigate = useNavigate()

  useEffect(() => {
    if (!firstCharge && token === '') navigate('/login', { replace: true })
  }, [firstCharge, token])

  return (
    <>
      <Helmet>
        <title>Outback Explorer Competition | Rescue Swag + Royal Flying Doctor Service QLD</title>
      </Helmet>
      <Container className="WatchNow-container">
        <Row className="mb-4">
          {data.videos_serializer?.map((element) => (
            <Col xl={3} md={4} sm={12} key={element.id}>
              <VideoCard
                id={element.id}
                socialNet={element.hosted_in}
                likes={element.votes}
                name={element.name}
                videoName={element.name}
                videoSrc={element.video_link}
                firstName={element.user_name}
                lastName={element.user_last_name}
                description={element.description}
                staffPick={element.is_staff_pick}
                image={element.thumbnail}
                voted={element.already_voted}
                video={element}
                refetch={refetch}
              />
            </Col>
          ))}
        </Row>
        {isFetching ? (
          <div className="text-center" style={{ marginBottom: '49.25px' }}>
            <Spinner animation="border" variant="warning" />
          </div>
        ) : data.videos_serializer.length === 0 ? (
          <div>No videos liked</div>
        ) : (
          <div style={{ height: '86px' }} />
        )}
      </Container>
    </>
  )
}

LikedVideos.propTypes = {}

export default LikedVideos
