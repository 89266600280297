import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { Col, Dropdown, Modal, Row, Spinner } from 'react-bootstrap'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import {
  faCircle as faCircleSolid,
  faX,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import CheckIcon from '../assets/landing/checkIcon.png'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { uploadVideo } from '../api/videos'
import PickImg from './PickImg'
import { countryList } from '../constants/CountryList'
import ModalInfoHowToEnter from './ModalInfoHowToEnter'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { AppContext } from '../context'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Compressor from 'compressorjs'

const FormContext = createContext()

const FormStepOne = ({ setStep }) => {
  const { formData, setFormData } = useContext(FormContext)
  const [userImg, setUserImg] = useState()
  const [showModalInfo, setShowModalInfo] = useState(false)
  const socialMediaList = ['TikTok', 'Instagram', 'Youtube']
  const [selectedMedia, setSelectedMedia] = useState(socialMediaList[0])
  const [isLoadingImg, setIsLoadingImg] = useState(false)

  const formik = useFormik({
    initialValues: {
      video_link: '',
      hosted_in: socialMediaList[0],
      name: '',
      description: '',
      thumbnail: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      video_link: Yup.string().required('Video link is required'),
      name: Yup.string()
        .required('Video name is required')
        .max(100, 'Max. 100 characters'),
      description: Yup.string()
        .required('Description is required')
        .max(400, 'Max. 400 characters'),
      thumbnail: Yup.string().required('Preview image is required'),
    }),
    onSubmit: async (values) => {
      const data = { ...formData, ...values }
      if (data.video_link) {
        data.video_link = await parseUrl(data.video_link)
      }

      let checkLink
      if (data.hosted_in === 'Instagram') {
        const instReg =
          /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_.]+)/
        checkLink = data.video_link.match(instReg)
      } else if (data.hosted_in === 'Youtube') {
        const YTReg =
          /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/
        checkLink = data.video_link.match(YTReg)
      } else if (data.hosted_in === 'TikTok') {
        const TTReg =
          /\bhttps?:\/\/(?:m|www|vm)\.tiktok\.com\/\S*?\b(?:(?:(?:usr|v|embed|video)\/|\?shareId=|&item_id=)(\d+)|(?=\w{7})(\w*?[A-Z\d]\w*)(?=\s|\/$))\b/
        checkLink = data.video_link.match(TTReg)
      }
      if (!checkLink)
        return formik.setFieldError(
          'video_link',
          'Please ensure the link matches your selected social network.'
        )

      setFormData(data)
      setStep((p) => p + 1)
    },
  })

  const handleInputImage = (e) => {
    setIsLoadingImg(true)
    const acceptedImageTypes = [
      'image/png',
      'image/jpg',
      'image/jpeg',
      'image/webp',
    ]
    const file = e.target.files[0]

    // to know file size
    // console.log(file.size / 1024 ** 2)

    if (!file) return setIsLoadingImg(false)
    if (!acceptedImageTypes.includes(file['type']))
      return setIsLoadingImg(false)

    const handleFile = (value) => {
      formik.setFieldValue('thumbnail', value)

      const imageReader = new FileReader()
      imageReader.onload = () => {
        if (imageReader.readyState === 2) {
          setUserImg(imageReader.result.toString())
        }
      }
      if (value) {
        imageReader.readAsDataURL(value)
      }

      formik.setFieldError('thumbnail', '')

      setIsLoadingImg(false)
    }

    if (file.size > 104857.6) {
      new Compressor(file, {
        quality: 0.6,
        success(result) {
          handleFile(result)
        },
        error() {
          formik.setFieldError('thumbnail', 'the selected image is too big.')
          setIsLoadingImg(false)
        },
      })
    } else {
      handleFile(file)
    }
  }

  const handleFilter = (value) => {
    formik.setFieldValue('hosted_in', value)
    setSelectedMedia(value)
  }

  const parseUrl = async (url) => {
    if (url.includes('youtu')) {
      const youtubeRegex =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
      let match = url.match(youtubeRegex)

      url =
        match && match[2].length === 11
          ? 'https://www.youtube.com/embed/' + match[2]
          : url
    }
    if (url.includes('tiktok')) {
      const tiktokRegex =
        /https?:\/\/(?:m|www|vt|vm)\.tiktok\.com\b\S*?(?:\/(?:use?r|v|embed|video)\/|\bshareId=)(\d+)\b/

      const tiktokRegex2 =
        /https?:\/\/(?:m|www|vt|vm)\.tiktok\.com\/([a-zA-Z0-9]{9})/

      let match = url.match(tiktokRegex)

      let videoId = null

      if (!match) {
        const matchCode = url.match(tiktokRegex2)
        if (matchCode) {
          if (url.includes('vm.tiktok'))
            url = url.replace('vm.tiktok', 'vt.tiktok')
          const response = await axios.get(
            `https://www.tiktok.com/oembed?url=${url}`
          )

          videoId = response.data.embed_product_id
        }
      } else videoId = match[1]

      url = videoId ? 'https://www.tiktok.com/embed/' + videoId : url
    }
    return url
  }

  const handleOpenModalInfo = () => {
    setShowModalInfo(true)
  }

  return (
    <Form className="w-100" onSubmit={formik.handleSubmit}>
      <Form.Group
        className="position-relative mb-4 fw-bold"
        controlId="video_link"
      >
        <Form.Label>Video posted on:</Form.Label>
        <Dropdown onSelect={handleFilter}>
          <Dropdown.Toggle variant="rs-white-yellow">
            {formik.values.hosted_in}{' '}
            <FontAwesomeIcon icon={faAngleDown} size="sm" className="ms-2" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {socialMediaList.map((socialMedia, index) => (
              <Dropdown.Item key={index} eventKey={socialMedia}>
                {socialMedia}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Form.Group>

      <Form.Group
        className="position-relative mb-4 fw-bold"
        controlId="video_link"
      >
        <Form.Label>Video link</Form.Label>
        <p
          className="d-inline ms-2 cursor-pointer text-6 text_Secondary text-decoration-underline"
          onClick={handleOpenModalInfo}
        >
          Where do I find this?
        </p>
        <Form.Control
          type="url"
          placeholder="Paste your video link here"
          name="video_link"
          value={formik.values.video_link}
          onChange={formik.handleChange}
          isInvalid={formik.errors.video_link}
        />
        <Form.Control.Feedback
          className="position-absolute mt-0 text-5"
          type="invalid"
        >
          {formik.errors.video_link}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="position-relative mb-4 fw-bold" controlId="name">
        <Form.Label>Video name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter a name for your video"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          isInvalid={formik.errors.name}
        />
        <p
          className={`position-absolute end-0 mt-1 text-6 ${
            formik.values.name.length > 100 ? 'text-red' : ''
          }`}
        >
          {formik.values.name.length}/100
        </p>
        <Form.Control.Feedback
          className="position-absolute mt-0 text-5"
          type="invalid"
        >
          {formik.errors.name}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group
        className="position-relative mb-4 fw-bold"
        controlId="description"
      >
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Describe what’s happening in your video"
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          isInvalid={formik.errors.description}
        />
        <p
          className={`position-absolute end-0 mt-1 text-6 ${
            formik.values.description.length > 400 ? 'text-red' : ''
          }`}
        >
          {formik.values.description.length}/400
        </p>
        <Form.Control.Feedback
          className="position-absolute mt-0"
          type="invalid"
        >
          {formik.errors.description}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group
        controlId="thumbnail"
        className="position-relative mb-4 fw-bold"
      >
        <Form.Label className="d-block cursor-pointer mb-0 text-5">
          <Row>
            <Col sm={3} className="position-relative">
              {userImg ? (
                <img
                  src={userImg}
                  className="img-fluid FormCompetition__Image"
                />
              ) : (
                <PickImg />
              )}
              {isLoadingImg ? (
                <div className="Loading_image">
                  <Spinner animation="border" variant="warning" />
                </div>
              ) : (
                <></>
              )}
            </Col>
            <Col>
              <p className="mb-1 fw-bold">Add preview image</p>
              <p className="mb-1 fw-light">
                Recommended dimensions 1200x900px.
              </p>
              <p className="mb-1 fw-light">Recommended size: 1 MB.</p>
            </Col>
          </Row>
        </Form.Label>
        <Form.Control
          className="d-none"
          type="file"
          name="thumbnail"
          onChange={(e) => handleInputImage(e)}
          accept="image/*"
          isInvalid={formik.errors.thumbnail}
        />
        <Form.Control.Feedback
          className="position-absolute mt-0 text-5"
          type="invalid"
        >
          {formik.errors.thumbnail}
        </Form.Control.Feedback>
      </Form.Group>
      <div className="d-grid gap-2 mt-auto mb-4 pt-1">
        <Button
          id="Enter-Next-Button-Btn"
          variant="rs"
          type="submit"
          disabled={formik.isSubmitting}
        >
          Next
        </Button>
      </div>
      <div className="text-center mb-4">
        <FontAwesomeIcon icon={faCircleSolid} className="me-3" />
        <FontAwesomeIcon icon={faCircle} />
      </div>
      <ModalInfoHowToEnter
        socialNet={selectedMedia}
        show={showModalInfo}
        close={() => setShowModalInfo(false)}
      />
    </Form>
  )
}

const FormStepTwo = ({ setStep, openShowModal }) => {
  const { formData, setFormData } = useContext(FormContext)
  const { userLog, setUserLog } = useContext(AppContext)
  const [serverError, setServerError] = useState('')

  const formik = useFormik({
    initialValues: {
      user_email: userLog.email,
      user_name: userLog.first_name,
      user_last_name: userLog.last_name,
      user_age: '',
      user_phone: '',
      user_country: 'Australia',
      user_city: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      user_email: Yup.string().required('Email is required'),
      user_name: Yup.string()
        .required('User name is required')
        .max(60, 'Max. 60 characters'),
      user_last_name: Yup.string()
        .required('User last name is required')
        .max(60, 'Max. 60 characters'),
      user_age: Yup.number()
        .required('User age is required')
        .min(13, 'You must be at least 13 years old to enter')
        .max(100, 'You must be at most 100 years old to enter'),
      user_phone: Yup.string()
        .required('Phone is required')
        .max(60, 'Max. 60 characters'),
      user_country: Yup.string()
        .required('Country is required')
        .max(60, 'Max. 60 characters'),
      user_city: Yup.string()
        .required('City is required')
        .max(60, 'Max. 60 characters'),
    }),
    onSubmit: async (values) => {
      setServerError('')
      const data = { ...formData, ...values }
      setFormData(data)

      const formdata = new FormData()
      Object.keys(data).forEach((value) => {
        formdata.append(value, data[value])
      })

      if (data.thumbnail) {
        formdata.append('thumbnail', data.thumbnail, 'profile-image.png')
      }

      setUserLog({
        email: values.user_email,
        first_name: values.user_name,
        last_name: values.user_last_name,
      })

      const response = await uploadVideo(formdata)

      if(response.status !== 200 && response.status !== 201) {
        if (
          response.data.message ===
          'A video has already sended with this email (and is approved or pending)'
        )
          setServerError(
            'A video has already been submitted using this email address. Please provide an alternative email address'
          )
        else if (response.data.message) {
          setServerError(response.data.message)
        } else {
          setServerError('Something went wrong 😕')
        }
      } else if (response.data) {
        openShowModal()
        setStep((p) => p + 1)
        setFormData({})
      }
    },
  }) 

  return (
    <Form className="w-100" onSubmit={formik.handleSubmit}>
      <h4 className="mb-4">About you</h4>
      <div className="text-danger">{serverError}</div>
      <Form.Group
        className="position-relative mb-4 fw-bold"
        controlId="user_email"
      >
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          name="user_email"
          value={formik.values.user_email}
          onChange={formik.handleChange}
          isInvalid={formik.errors.user_email}
          autoComplete="email"
        />
        <Form.Control.Feedback
          className="position-absolute mt-0 text-5"
          type="invalid"
        >
          {formik.errors.user_email}
        </Form.Control.Feedback>
      </Form.Group>

      <Row className="mb-4 fw-bold">
        <Form.Group
          className="position-relative"
          as={Col}
          controlId="formGridEmail"
        >
          <Form.Label>First name</Form.Label>
          <Form.Control
            type="text"
            name="user_name"
            value={formik.values.user_name}
            onChange={formik.handleChange}
            isInvalid={formik.errors.user_name}
            autoComplete="given-name"
          />
          <Form.Control.Feedback
            className="position-absolute mt-0 text-5"
            type="invalid"
          >
            {formik.errors.user_name}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          className="position-relative"
          as={Col}
          controlId="formGridPassword"
        >
          <Form.Label>Last name</Form.Label>
          <Form.Control
            type="text"
            name="user_last_name"
            value={formik.values.user_last_name}
            onChange={formik.handleChange}
            isInvalid={formik.errors.user_last_name}
            autoComplete="family-name"
          />
          <Form.Control.Feedback
            className="position-absolute mt-0 text-5"
            type="invalid"
          >
            {formik.errors.user_last_name}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-4 fw-bold  ">
        <Form.Group
          className="position-relative"
          as={Col}
          controlId="formGridEmail"
        >
          <Form.Label>Age</Form.Label>
          <Form.Control
            type="number"
            name="user_age"
            value={formik.values.user_age}
            onChange={formik.handleChange}
            isInvalid={formik.errors.user_age}
            autoComplete="age"
          />
          <Form.Control.Feedback
            className="position-absolute mt-0 text-5"
            type="invalid"
          >
            {formik.errors.user_age}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          className="position-relative"
          as={Col}
          controlId="formGridPassword"
        >
          <Form.Label>Mobile</Form.Label>
          <Form.Control
            type="text"
            name="user_phone"
            value={formik.values.user_phone}
            onChange={formik.handleChange}
            isInvalid={formik.errors.user_phone}
            autoComplete="tel"
          />
          <Form.Control.Feedback
            className="position-absolute mt-0 text-5"
            type="invalid"
          >
            {formik.errors.user_phone}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-4 fw-bold  ">
        <Form.Group
          className="position-relative"
          as={Col}
          controlId="formGridEmail"
        >
          <Form.Label>Country</Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="user_country"
            value={formik.values.user_country}
            onChange={formik.handleChange}
            isInvalid={formik.errors.user_country}
            autoComplete="country-name"
          >
            {countryList.map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback
            className="position-absolute mt-0 text-5"
            type="invalid"
          >
            {formik.errors.user_country}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          className="position-relative"
          as={Col}
          controlId="formGridPassword"
        >
          <Form.Label>City</Form.Label>
          <Form.Control
            type="text"
            name="user_city"
            value={formik.values.user_city}
            onChange={formik.handleChange}
            isInvalid={formik.errors.user_city}
            autoComplete="address-level2"
          />
          <Form.Control.Feedback
            className="position-absolute mt-0 text-5"
            type="invalid"
          >
            {formik.errors.user_city}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <div className="d-grid gap-2 mt-auto mb-4 pt-1">
        <Button
          id="Enter-Upload-Button-Btn"
          variant="rs"
          type="submit"
          disabled={formik.isSubmitting}
        >
          {!formik.isSubmitting ? (
            'Upload video'
          ) : (
            <Spinner
              animation="border"
              variant="dark mx-auto"
              className="FormCompetition__FormStepTwo_Spinner"
            />
          )}
        </Button>
      </div>
      <div className="text-center">
        <FontAwesomeIcon
          icon={faCircleSolid}
          className="me-3 cursor-pointer"
          onClick={() => setStep((p) => p - 1)}
        />
        <FontAwesomeIcon icon={faCircleSolid} />
      </div>
    </Form>
  )
}

const FormStepThree = ({ setStep }) => {
  return (
    <div className="FormCompetition__FormStepThree text-center">
      <img src={CheckIcon} className="mb-4" width={40} />
      <h4 className="mb-4">Your video has been submitted!</h4>
      <p>
        We will review your video shortly and confirm via email once it has been
        approved.
      </p>
      <p>
        <strong>Good luck!</strong>
      </p>
      <p onClick={() => setStep(0)}>
        <u>To reset the entry form, please click here.</u>
      </p>
    </div>
  )
}

const FormCompetition = () => {
  const { token } = useContext(AppContext)
  const [step, setStep] = useState(0)
  const [formData, setFormData] = useState({})
  const [showModal, setshowModal] = useState(false)

  const openShowModal = () => setshowModal(true)
  const closeShowModal = () => setshowModal(false)

  const componentsList = [
    <FormStepOne key={0} setStep={setStep} />,
    <FormStepTwo key={1} setStep={setStep} openShowModal={openShowModal} />,
    <FormStepThree key={2} setStep={setStep} />,
  ]

  return (
    <>
      <FormContext.Provider value={{ formData, setFormData }}>
        <SwitchTransition>
          <CSSTransition key={step} timeout={300}>
            <div className="FormCompetition__Container">
              {componentsList[step]}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </FormContext.Provider>
      <Modal
        centered
        size="md"
        show={showModal}
        dialogClassName={'Video_Submitted'}
        onHide={closeShowModal}
      >
        <Modal.Body className="p-5 text-center">
          <div
            className="float-end text-end cursor-pointer"
            onClick={() => closeShowModal()}
          >
            <FontAwesomeIcon icon={faX} size="xl" />
          </div>
          <img src={CheckIcon} className="mb-4" width={40} />
          <h4 className="mb-4">Your video has been submitted</h4>
          <p>Your application will be reviewed soon.</p>

          {!token && (
            <p>
              Whilst you are waiting, sign up for an account to{' '}
              <strong>cast five votes per day</strong> plus be entered into the
              draw to <strong>win a Rescue Swag</strong>!
            </p>
          )}

          <p>Good luck!</p>
          {!token && (
            <div className="d-grid gap-2 mt-5">
              <Link to="/register">
                <Button variant={'rs'} className="px-5">
                  Create account
                </Button>
              </Link>
              <Link to="/login">
                <Button variant={'rs-dark'} className="px-5">
                  Log in
                </Button>
              </Link>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default FormCompetition

FormStepOne.propTypes = {
  setStep: PropTypes.func.isRequired,
}

FormStepTwo.propTypes = {
  setStep: PropTypes.func.isRequired,
  openShowModal: PropTypes.func.isRequired,
}

FormStepThree.propTypes = {
  setStep: PropTypes.func.isRequired,
}
