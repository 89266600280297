import {
  Col,
  Row,
  Container,
  InputGroup,
  FormControl,
  Modal,
} from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
// import AuthImg from '../../assets/auth/authimg.png'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { register, resendEmailConfirmation } from '../../api/users'
import { useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { Link, useNavigate } from 'react-router-dom'
import checkIcon from '../../assets/landing/checkIcon.png'
import { AppContext } from '../../context'
import { Helmet } from 'react-helmet'

const Register = () => {
  const { userLog, setUserLog } = useContext(AppContext)
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
  const [showEmailModal, setShowEmailModal] = useState(false)
  const [disableReSendMail, setDisableReSendMail] = useState(false)
  const [serverError, setServerError] = useState('')
  const navigate = useNavigate()

  const handlerShowPassword = () => {
    setShowPassword((prev) => !prev)
  }
  const handlerShowPasswordConfirm = () => {
    setShowPasswordConfirm((prev) => !prev)
  }

  const closeShowEmailModal = () => {
    navigate('/login')
  }

  const formik = useFormik({
    initialValues: {
      email: userLog.email,
      password: '',
      passwordConfirm: '',
      first_name: userLog.first_name,
      last_name: userLog.last_name,
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      email: Yup.string()
        .required('E-mail is required.')
        .max(60, 'Maximum characters: 60.'),
      password: Yup.string()
        .required('Password is required.')
        .min(8, 'Minimum characters: 8.')
        .max(40, 'Maximum characters: 40.'),
      passwordConfirm: Yup.string()
        .required('Confirm password is required.')
        .min(8, 'Minimum characters: 8.')
        .max(40, 'Maximum characters: 40.'),
      first_name: Yup.string()
        .required('Your first name is required.')
        .max(60, 'Maximum characters: 60.'),
      last_name: Yup.string()
        .required('Your last name is required.')
        .max(60, 'Maximum characters: 60.'),
    }),
    onSubmit: async ({
      email,
      password,
      passwordConfirm,
      first_name,
      last_name,
    }) => {
      if (password !== passwordConfirm) {
        formik.setFieldError('password', 'passwords don\'t match')
        formik.setFieldError('passwordConfirm', 'passwords don\'t match')
        return null
      }
      if (email.includes(' ')) {
        email = email.replace(/ /g, '')
      }

      const body = {
        email,
        password1: password,
        password2: passwordConfirm,
        first_name,
        last_name,
      }

      setUserLog({
        email,
        first_name,
        last_name,
      })

      const response = await register(body)

      if(response.status !== 200 && response.status !== 201) {
        if (response.data.email)
          formik.setFieldError(
            'email',
            'A user is already registered with this e-mail address.'
          )
        if (response.data.password1) 
          formik.setFieldError(
            'password',
            response.data.password1[0]
          )
        if (response.data.password2) 
          formik.setFieldError(
            'passwordConfirm',
            response.data.password1[0]
          )
        if (response.data.first_name) 
          formik.setFieldError(
            'first_name',
            response.data.first_name[0]
          )
        if (response.data.last_name) 
          formik.setFieldError(
            'last_name',
            response.data.last_name[0]
          )

        if(response.data.error || response.data.message || response.data.detail)
          setServerError('Something went wrong 😕')
  
      } else if (response.data) {
        setShowEmailModal(true)
      }
    },
  })

  const reSendMail = async () => { 
    setDisableReSendMail(true)
    const response = await resendEmailConfirmation({ email: formik.values.email })
    
    if(response.error) console.error(response.error)
    else if (response) {
      console.log('mail forwarded')
    }
    setDisableReSendMail(false)
  }

  return (
    <>
      <Helmet>
        <title>Sign Up For More Votes | Outback Explorer Competition</title>
      </Helmet>
      <div className="AuthContainer">
        <Container fluid>
          <Row>
            <Col
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
              xxl={{ span: 4, offset: 4 }}
              className="mt-5"
            >
              <div className="bg-white AuthBox mb-4">
                <Container fluid>
                  <Row>
                    <Col className="p-4">
                      <div className="text-center">
                        <h1 className="mb-4">Sign up</h1>
                        <p>
                          Create an account to cast five votes per day plus be
                          entered into the draw to win an Adventurer Rescue
                          Swag!
                        </p>
                      </div>
                      <Form
                        className="position-relative mb-4"
                        onSubmit={formik.handleSubmit}
                      >
                        <Row className="mb-4 fw-bold">
                          <Form.Group
                            as={Col}
                            controlId="first_name"
                            className="position-relative"
                          >
                            <Form.Label>First name</Form.Label>
                            <Form.Control
                              type="text"
                              value={formik.values.first_name}
                              onChange={formik.handleChange}
                              isInvalid={!!formik.errors.first_name}
                              autoComplete="given-name"
                            />
                            <Form.Control.Feedback
                              className="position-absolute mt-0 text-5"
                              type="invalid"
                            >
                              {formik.errors.first_name}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            controlId="last_name"
                            className="position-relative"
                          >
                            <Form.Label>Last name</Form.Label>
                            <Form.Control
                              type="text"
                              value={formik.values.last_name}
                              onChange={formik.handleChange}
                              isInvalid={!!formik.errors.last_name}
                              autoComplete="family-name"
                            />
                            <Form.Control.Feedback
                              className="position-absolute mt-0 text-5"
                              type="invalid"
                            >
                              {formik.errors.last_name}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>

                        <Form.Group className="mb-4 fw-bold" controlId="email">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            isInvalid={!!formik.errors.email}
                            autoComplete="email"
                          />
                          <Form.Control.Feedback
                            className="position-absolute mt-0 text-5"
                            type="invalid"
                          >
                            {formik.errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          className="mb-4 fw-bold"
                          controlId="password"
                        >
                          <Form.Label>Password</Form.Label>
                          <InputGroup
                            className="position-relative"
                            hasValidation
                          >
                            <FormControl
                              type={showPassword ? 'text' : 'password'}
                              value={formik.values.password}
                              onChange={formik.handleChange}
                              isInvalid={!!formik.errors.password}
                              autoComplete="new-password"
                            />
                            <Button
                              variant="outline-secondary"
                              onClick={handlerShowPassword}
                            >
                              {showPassword ? (
                                <FontAwesomeIcon icon={faEyeSlash} />
                              ) : (
                                <FontAwesomeIcon icon={faEye} />
                              )}
                            </Button>
                            <Form.Control.Feedback
                              className="position-absolute top-100 mt-0 text-5"
                              type="invalid"
                            >
                              {formik.errors.password}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>

                        <Form.Group
                          className="mb-4 fw-bold"
                          controlId="passwordConfirm"
                        >
                          <Form.Label>Confirm password</Form.Label>
                          <InputGroup
                            className="position-relative"
                            hasValidation
                          >
                            <FormControl
                              type={showPasswordConfirm ? 'text' : 'password'}
                              value={formik.values.passwordConfirm}
                              onChange={formik.handleChange}
                              isInvalid={!!formik.errors.passwordConfirm}
                              autoComplete="new-password"
                            />
                            <Button
                              variant="outline-secondary"
                              onClick={handlerShowPasswordConfirm}
                            >
                              {showPasswordConfirm ? (
                                <FontAwesomeIcon icon={faEyeSlash} />
                              ) : (
                                <FontAwesomeIcon icon={faEye} />
                              )}
                            </Button>
                            <Form.Control.Feedback
                              className="position-absolute top-100 mt-0 text-5"
                              type="invalid"
                            >
                              {formik.errors.passwordConfirm}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>

                        {serverError ? <p>{serverError}</p> : <></>}

                        <div className="d-grid gap-2 mt-auto pt-2">
                          <Button variant="rs" type="submit">
                            Sign up
                          </Button>
                        </div>
                      </Form>
                      <div className="AlreadyAccount">
                        <Link to="/login" className="d-grid gap-2 mt-auto">
                          <Button variant="rs-white-yellow">
                            I already have an account
                          </Button>
                        </Link>
                      </div>
                    </Col>
                    {/* <Col sm={12} md={6} className="p-0">
                      <div className="RightAuth">
                        <img src={AuthImg} className="img-fluid" alt="" />
                        <div className="AuthBoxPrice">
                          <h3>Want to win a prize?</h3>
                          <p className="border-top border-color-yellow pt-4 text-center">
                          Creating an account not only will{' '}
                            <span className="fw-bold">triple your votes</span> but
                          also all account creators will also be entered into a
                          draw to{' '}
                            <span className="fw-bold">
                            win an Adventurer Rescue Swag first aid kit.
                            </span>
                          </p>
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        centered
        size="lg"
        // size="sm"
        // dialogClassName="modal-60w"
        show={showEmailModal}
      >
        <Modal.Body className="py-4 px-4 px-sm-5 py-sm-5">
          <div className="d-flex flex-column justify-content-center align-items-center gap-3">
            <img src={checkIcon} alt="check icon" className="check-icon" />
            <p className="text-1 text-center fw-semibold">
              {'Your account has been created!'}
            </p>
          </div>
          <div className="mx-auto mt-4" style={{maxWidth: '500px'}}>
            <p className="text-3">
              You will receive an email confirmation shortly. Please check your
              inbox to activate your voting account.
            </p>
            <p className="text-3">
              Once activated, you will be entered into the draw to win a Rescue
              Swag and your 5 daily votes will be ready to use.
            </p>
            <p className="text-3">
              Please note that your additional votes will only be active when
              you are logged in to your account on the <strong>Outback Explorer
              Competition</strong> website.
            </p>
          </div>
          <div className="d-flex flex-column justify-content-center gap-3 w-100 max-w-400px mx-auto mt-4">
            <Button variant="rs px-5" onClick={closeShowEmailModal}>
              Log in
            </Button>

            <Button variant='rs-white px-5 text-decoration-underline' disabled={disableReSendMail} onClick={reSendMail}>
              {disableReSendMail ? 'sending...' : 'I did not receive email. Re-send'}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Register
