import { Col, Row, Container, InputGroup, FormControl } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
// import AuthImg from '../../assets/auth/authimg.png'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useContext, useState } from 'react'
import { checkPasswordRecoveryToken, passwordRecovery, passwordRecoveryConfirm } from '../../api/users'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { useNavigate } from 'react-router'
import checkIcon from '../../assets/landing/checkIcon.png'
import { AppContext } from '../../context'
import { Helmet } from 'react-helmet'

const ForgotPassword = () => {
  const { userLog } = useContext(AppContext)
  const [step, setStep] = useState(1)
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)

  const handlerShowPassword = () => {
    setShowPassword((prev) => !prev)
  }
  const handlerShowPasswordConfirm = () => {
    setShowPasswordConfirm((prev) => !prev)
  }

  const goToLogin = () => { 
    navigate('/login')
  }

  const formik1 = useFormik({
    initialValues: {
      email: userLog.email,
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      email: Yup.string()
        .required('E-mail is required.')
        .max(60, 'Maximum characters: 60.'),
    }),
    onSubmit: async ({ email }) => {
      if (email.includes(' ')) {
        email = email.replace(/ /g, '')
      }

      const response = await passwordRecovery({ email })

      if (response.e === 'No User matches the given query.')
        formik1.setFieldError('email', 'E-mail not found.')
      else if (response.message === 'Email sent') setStep((prev) => prev + 1)
    },
  })

  const formik2 = useFormik({
    initialValues: {
      token: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      token: Yup.string()
        .required('Token is required.')
        .max(60, 'Maximum characters: 60.'),
    }),
    onSubmit: async ({ token }) => {
      const response = await checkPasswordRecoveryToken({
        email: formik1.values.email,
        token,
      })

      if (response.error)
        formik2.setFieldError('token', 'Token is invalid')
      else if (response.message === 'Token is valid')
        setStep((prev) => prev + 1)
    },
  })

  const formik3 = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Password is required.')
        .min(5, 'Minimum characters: 5.')
        .max(40, 'Maximum characters: 40.'),
      passwordConfirm: Yup.string()
        .required('Confirm password is required.')
        .min(5, 'Minimum characters: 5.')
        .max(40, 'Maximum characters: 40.'),
    }),
    onSubmit: async ({ password, passwordConfirm }) => {
      if (password !== passwordConfirm) {
        formik3.setFieldError('password', 'passwords don\'t matchn')
        formik3.setFieldError('passwordConfirm', 'passwords don\'t matchn')
        return null
      }
      const response = await passwordRecoveryConfirm({
        email: formik1.values.email,
        token: formik2.values.token,
        password,
      })

      if (response.error) {
        if(response.error[0] === 'The password is too similar to the username.')
          formik3.setFieldError(
            'password',
            'The password is too similar to the username.'
          )
        else if (response.error[0] === 'This password is too common.')
          formik3.setFieldError('password', 'This password is too common.')
        else 
          console.error(response.error)
      } else if (response)
        setStep((prev) => prev + 1)
    },
  })

  return (
    <>
      <Helmet>
        <title>Reset Password | Outback Explorer Competition</title>
      </Helmet>
      <div className="AuthContainer">
        <Container fluid>
          <Row>
            <Col
              md={{ span: 8, offset: 2 }}
              lg={{span: 6, offset: 3 }}
              xxl={{ span: 4, offset: 4 }}
              className="mt-5"
            >
              <div className="bg-white AuthBox mb-4">
                <Row>
                  <Col className="p-4">
                    <h1 className="mb-4 text-center">Reset password</h1>
                    {step === 1 && (
                      <Form className="mb-4" onSubmit={formik1.handleSubmit}>
                        <p className="text-center">
                        Please enter your email and we will send a code to reset your password.
                        </p>
                        <Form.Group className="position-relative mb-4 fw-bold" controlId="email">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            value={formik1.values.email}
                            onChange={formik1.handleChange}
                            isInvalid={formik1.errors.email}
                          />
                          <Form.Control.Feedback className="position-absolute mt-0 text-5" type="invalid">
                            {formik1.errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
 
                        <div className="d-grid gap-2 mt-auto pt-2">
                          <Button variant="rs" type="submit">
                          Send code
                          </Button>
                        </div>
                      </Form>
                    )}
                    {step === 2 && (
                      <Form className="mb-4" onSubmit={formik2.handleSubmit}>
                        <p className='text-center'>Please input the code we sent to your email.</p>
                        <Form.Group className="position-relative mb-4 fw-bold" controlId="token">
                          <Form.Label>Code</Form.Label>
                          <Form.Control
                            type="text"
                            value={formik2.values.token}
                            onChange={formik2.handleChange}
                            isInvalid={formik2.errors.token}
                          />
                          <Form.Control.Feedback className="position-absolute mt-0 text-5" type="invalid">
                            {formik2.errors.token}
                          </Form.Control.Feedback>
                        </Form.Group>
 
                        <div className="d-grid gap-2 mt-auto pt-2">
                          <Button variant="rs" type="submit">
                          Confirm code
                          </Button>
                        </div>
                      </Form>
                    )}
                    {step === 3 && (
                      <Form className="mb-4" onSubmit={formik3.handleSubmit}>
                        <p className='text-center'>Input new password.</p>
                        <Form.Group className="mb-4 fw-bold" controlId="password">
                          <Form.Label>Password</Form.Label>
                          <InputGroup className="position-relative">
                            <FormControl
                              type={showPassword ? 'text' : 'password'}
                              value={formik3.values.password}
                              onChange={formik3.handleChange}
                              isInvalid={!!formik3.errors.password}
                            />
                            <Button
                              variant="outline-secondary"
                              onClick={handlerShowPassword}
                            >
                              {showPassword ? (
                                <FontAwesomeIcon icon={faEyeSlash} />
                              ) : (
                                <FontAwesomeIcon icon={faEye} />
                              )}
                            </Button>
                            <Form.Control.Feedback className="position-absolute top-100 mt-0 text-5" type="invalid">
                              {formik3.errors.password}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>

                        <Form.Group
                          className="mb-4 fw-bold"
                          controlId="passwordConfirm"
                        >
                          <Form.Label>Confirm password</Form.Label>
                          <InputGroup className="position-relative">
                            <FormControl
                              type={showPasswordConfirm ? 'text' : 'password'}
                              value={formik3.values.passwordConfirm}
                              onChange={formik3.handleChange}
                              isInvalid={!!formik3.errors.passwordConfirm}
                            />
                            <Button
                              variant="outline-secondary"
                              onClick={handlerShowPasswordConfirm}
                            >
                              {showPasswordConfirm ? (
                                <FontAwesomeIcon icon={faEyeSlash} />
                              ) : (
                                <FontAwesomeIcon icon={faEye} />
                              )}
                            </Button>
                            <Form.Control.Feedback className="position-absolute top-100 mt-0 text-5" type="invalid">
                              {formik3.errors.passwordConfirm}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
 
                        <div className="d-grid gap-2 mt-auto pt-2">
                          <Button variant="rs" type="submit">
                          Change Password
                          </Button>
                        </div>
                      </Form>
                    )}
                    {step === 4 && (
                      <div className="d-flex flex-column justify-content-evenly align-items-center h-75 mb-4">
                        <div className='d-flex flex-column justify-content-center align-items-center gap-3'>
                          <img src={checkIcon} alt="check icon" className='check-icon' />
                          <p className='text-1'>All done!</p>
                        </div>
                        <Button variant="rs" onClick={goToLogin}>
                          Go back to login
                        </Button>
                      </div>
                    )}
                  </Col>
                  {/* <Col sm={12} md={6}>
                  <div className="RightAuth">
                    <img src={AuthImg} className="img-fluid" alt="" />
                    <div className="AuthBoxPrice">
                      <h3>Want to win a prize?</h3>
                      <p className="border-top border-color-yellow pt-4 text-center">
                        Creating an account not only will{' '}
                        <span className="fw-bold">triple your votes</span> but
                        also all account creators will also be entered into a
                        draw to{' '}
                        <span className="fw-bold">
                          win an Adventurer Rescue Swag first aid kit.
                        </span>
                      </p>
                    </div>
                  </div>
                </Col> */}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}


export default ForgotPassword